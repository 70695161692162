import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  SnackbarContent,
  Box,
  InputLabel,
  Divider,
  Typography
} from '@material-ui/core';


import { LoadingButton } from '@material-ui/lab';
// import InputPhone from '../../../components/country-code-input';
import { useRegister } from './hooks';
import whatsappicon from '../../../assests/Images/whatsapp-svgrepo-com.png';


// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { formik, horizontal, open, responseState, vertical, handleClose } = useRegister();

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form style={{marginLeft:'30px',marginRight:"80px"}}  autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Snackbar
          severity="red"
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          {responseState.error ? (
            <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
          ) : (
            <SnackbarContent style={{ backgroundColor: 'green' }} message={responseState.msg} />
          )}
        </Snackbar>
        <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstname')}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastname')}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <InputPhone
              fullWidth
              specialLabel="Country Code"
              name="countryCode"
              {...getFieldProps('countryCode')}
              // value={rest.values.countryCode}
              // onPhoneChange={(value) => {
              // handleFormUpdate({ target: { name: 'countryCode', value } });
              // }}
              helperText={errors.countryCode}
              error={Boolean(errors.countryCode)}
            />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>
          <Box display="flex"  justifyContent='center' alignItems='center' gap="5px" paddingLeft="20px" paddingTop="10px">
            {/* <Checkbox
              size="small"
              style={{ marginTop: '-7px' }}
              name="isWhatsAppOptIn"
              {...getFieldProps('isWhatsAppOptIn')}
              // aria-checked={values.isWhatsAppOptIn}
              // checked={values.isWhatsAppOptIn}
              // value={rest.values.isWhatsAppOptIn}
              // onChange={handleChange}
              type="checkbox"
            /> */}
            <FormControlLabel
            control={<Checkbox style={{marginTop:'-6px'}} {...getFieldProps('isWhatsAppOptIn')} checked={values.isWhatsAppOptIn} />}
            label=""
          />
            <InputLabel
              htmlFor="isWhatsAppOptIn"
              style={{ fontSize: '14px', display: 'flex', gap: '5px',marginLeft:'-20px'}}
            >
              <div> Provided number is your</div>{' '}
              <img width="22px" height="22px" src={whatsappicon} alt="" />{' '}
              <div> WhatsApp number</div>
            </InputLabel>
          </Box>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              autoComplete="confirmPassword"
              type={showCPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowCPassword((prev) => !prev)}>
                      <Icon icon={showCPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Grid> */}


          <Grid item xs={12}>
          <Divider style={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} variant='middle' >Organization Details</Divider>
        </Grid>
          
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Organization Name"
              {...getFieldProps('entityName')}
              error={Boolean(touched.entityName && errors.entityName)}
              helperText={touched.entityName && errors.entityName}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Site Name"
              {...getFieldProps('siteName')}
              error={Boolean(touched.siteName && errors.siteName)}
              helperText={touched.siteName && errors.siteName}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Site Address"
              {...getFieldProps('siteAddress')}
              error={Boolean(touched.siteAddress && errors.siteAddress)}
              helperText={touched.siteAddress && errors.siteAddress}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Site Short Name"
              {...getFieldProps('siteShortName')}
              error={Boolean(touched.siteShortName && errors.siteShortName)}
              helperText={touched.siteShortName && errors.siteShortName}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('sitebleedSubscription')} checked={values.sitebleedSubscription} />}
            label="Bleed Subscription"
          />
          </Grid> */}
        
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Register
            </LoadingButton>
          </Grid>
        </Grid>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
