import React, { useState, useEffect } from 'react';
// import * as Yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Modal,
  TextField,
  InputLabel,
  Box,
  Button,
  Select,
  MenuItem,
  SnackbarContent,
  Snackbar,
  Grid,
  FormControl,
  Input,
  Chip
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import * as Yup from 'yup';
import { fetchSites } from '../../api/uploadStudy/fetchSites';
import { fetchRoles } from '../../api/userList/addUser/fetchRoles';
import { fetchUser } from '../../api/userList/fetchUser';
import ChangePassword from './ChangePassword';

const EditUser = () => {
  const edituserId = useSelector((state) => state.editUser.userId);
  const { user } = useSelector((state) => state.loggedInUser);

  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const chipStyle = {
    border: '1px solid #EDEFF1',
    backgroundColor: '#EDEFF1',
    margin: '0 2px',
    fontSize: '0.85rem',
    color: 'black',
    padding: '12px',
    borderRadius: '50%'
  };

  const [editUserForm, setEditUserForm] = useState({});
  const [sites, setSites] = useState([]);

  const [modalStyle] = useState(getModalStyle);
  const [modalOpen, setModalOpen] = useState(false);

  function getModalStyle() {
    const top = 20;
    const left = 26;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${left}%, ${top}%)`
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 450,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    closeBtn: {
      position: 'relative',
      top: '0',
      left: '100%',
      '&:hover': {
        backgroundColor: 'lightgrey',
        border: '1px solid lightgrey',
        borderRadius: '50%',
        padding: '2px'
      }
    }
  }));

  const Classes = useStyles();

  const { firstname, lastname, email, phone } = editUserForm;

  const siteNameArray = [];

  for (let i = 0; i < sites.length; i += 1) {
    if (editUserForm.sites === undefined) {
      siteNameArray.push(' ');
    } else if (editUserForm.sites.includes(sites[i]._id)) {
      siteNameArray.push(sites[i].name);
    }
  }

  const [siteId, setSiteId] = useState('');
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedSites, setSelectedSites] = useState(siteNameArray);
  const [unHideRole, setUnHideRole] = useState(false);
  const [unHideSites, setUnHideSites] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleChange = (event) => {
    setSelectedSites(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchEditUser = async () => {
      const res = await fetchUser(edituserId);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 201) {
          setEditUserForm(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchEditUser();
  }, [edituserId]);

  useEffect(() => {
    const fetchsites = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchsites();
  }, []);

  useEffect(() => {
    const fetchroles = async () => {
      const res = await fetchRoles();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setRoles(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchroles();
  }, []);

  const handleSiteId = (id) => {
    setSelectedSites(id);
    setSiteId(id);
  };

  function handleReset() {
    setSelectedSites([]);
    setSelectedRole('');
    setUnHideRole(false);
    setUnHideSites(false);
  }

  const roleName = roles.filter((role) => role._id === editUserForm.role).map((role) => role.name);

  const siteIdArray = [];

  for (let i = 0; i < sites.length; i += 1) {
    if (selectedSites === undefined) {
      siteIdArray.push(' ');
    } else if (selectedSites.includes(sites[i].name)) {
      siteIdArray.push(sites[i]._id);
    }
  }

  const userSchema = Yup.object().shape({
    username: Yup.string().required('username is required'),
    firstname: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Invalid Phone number')
  });

  const formik = useFormik({
    initialValues: {
      username: editUserForm.username,
      firstname: editUserForm.firstname,
      lastname: editUserForm.lastname,
      email: editUserForm.email,
      phone: editUserForm.phone,
      sites: '',
      role: ''
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: async (value) => {
      if (siteIdArray.length !== 0) {
        value.sites = siteIdArray;
      } else {
        value.sites = editUserForm.sites;
      }

      if (selectedRole) {
        value.role = selectedRole;
      } else {
        value.role = editUserForm.role;
      }

      if (firstname === value.firstname) {
        value.firstname = '';
      }

      if (lastname === value.lastname) {
        value.lastname = '';
      }

      if (email === value.email) {
        value.email = '';
      }

      if (phone === value.phone) {
        value.phone = '';
      }

      const res = await axios
        .patch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${edituserId}`, value)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Breadcrumbs aria-label="breadcrumb" my={3}>
              <Link color="inherit" to="/dashboard/users">
                Users
              </Link>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button style={{margin:'10px 5px 0px 5px'}} variant="contained" color="primary">Change State</Button> */}
            {/* <Button style={{margin:'10px 5px 0px 5px'}} variant="contained" color="primary" onClick={()=> {setModalOpen(true)}}>Change Password</Button> */}
          </div>
        </div>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="firstname" style={marginStyles}>
              First Name
            </InputLabel>
            <TextField
              name="firstname"
              variant="outlined"
              fullWidth
              {...getFieldProps('firstname')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="lastname" style={marginStyles}>
              Last Name
            </InputLabel>
            <TextField
              name="lastname"
              variant="outlined"
              fullWidth
              {...getFieldProps('lastname')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="email" style={marginStyles}>
              Email <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="email"
              variant="outlined"
              fullWidth
              {...getFieldProps('email')}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="phone" style={marginStyles}>
              Mobile Number
            </InputLabel>
            <TextField
              name="phone"
              variant="outlined"
              fullWidth
              {...getFieldProps('phone')}
              helperText={touched.phone && errors.phone}
              error={Boolean(touched.phone && errors.phone)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="username" style={marginStyles}>
              Username <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              disabled
              name="username"
              variant="outlined"
              fullWidth
              {...getFieldProps('username')}
              helperText={touched.username && errors.username}
              error={Boolean(touched.username && errors.username)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="sites" style={marginStyles}>
              Sites
            </InputLabel>
            <TextField
              disabled
              name="sites"
              variant="outlined"
              fullWidth
              label={siteNameArray.map((value, index) => {
                const siteObj = {
                  index,
                  value
                };
                return (
                  <span key={siteObj.index} style={chipStyle}>
                    {siteObj.value}
                  </span>
                );
              })}
            />
          </Grid>
          {!unHideSites ? (
            <Grid item sm={6} xs={12}>
              {/* <Button style={{marginTop:'55px',padding:'6px 25px'}} variant='contained' color='primary' onClick={()=> {setUnHideSites(true);setSelectedSites(siteNameArray)}}>Edit Sites</Button> */}
            </Grid>
          ) : (
            <Grid item sm={6} xs={12}>
              <FormControl style={{ width: '100%', marginTop: '32px' }}>
                <InputLabel htmlFor="sites" style={{ marginTop: '10px' }}>
                  Select Sites
                </InputLabel>
                <Select
                  name="sites"
                  multiple
                  value={selectedSites}
                  onChange={handleChange}
                  input={<Input />}
                  labelId="sites"
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: '0px 2px' }} />
                      ))}
                    </div>
                  )}
                  style={{
                    width: '100%',
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    padding: '10px 0'
                  }}
                >
                  <MenuItem disabled>Select Sites</MenuItem>
                  {sites.map((site) => (
                    <MenuItem
                      key={site._id}
                      value={site.name}
                      onClick={() => {
                        handleSiteId(site._id);
                      }}
                    >
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="role" style={marginStyles}>
              Role <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              disabled
              name="role"
              variant="outlined"
              fullWidth
              label={roleName.map((value, index) => {
                const roleObj = {
                  index,
                  value
                };
                return (
                  <span key={roleObj.index} style={chipStyle}>
                    {roleObj.value}
                  </span>
                );
              })}
            />
          </Grid>

          {!unHideRole ? (
            <Grid item sm={6} xs={12}>
              {/* {
                    user.role !== 'Admin' && 
                    <Button style={{marginTop:'55px'}} variant='contained' color='primary' onClick={()=> {setUnHideRole(true)}}>Change Role</Button>
                  } */}
            </Grid>
          ) : (
            <Grid item sm={6} xs={12}>
              <FormControl style={{ width: '100%', marginTop: '47px' }}>
                <InputLabel htmlFor="role" style={{ marginTop: '-7px' }}>
                  Select Role
                </InputLabel>
                <Select
                  name="role"
                  variant="outlined"
                  fullWidth
                  value={selectedRole}
                  onChange={handleRoleChange}
                >
                  <MenuItem disabled>Select Role</MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role._id} value={role._id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Box mt={3}>
          <Button
            type="reset"
            variant="contained"
            color="primary"
            style={{ marginRight: '20px' }}
            onClick={handleReset}
          >
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Update{' '}
          </Button>
        </Box>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <div style={modalStyle} className={Classes.paper}>
            <CloseIcon className={Classes.closeBtn} onClick={() => setModalOpen(false)} />
            <ChangePassword userId={edituserId} />
          </div>
        </Modal>
      </Form>
    </FormikProvider>
  );
};

export default EditUser;
