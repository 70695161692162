import axios from 'axios';
import { setLogoutStatus } from './redux/actions/authActions';
import store from './redux/store';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/authentication/login/Login';

// ----------------------------------------------------------------------

export default function App() {

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('loggedInUser');
        localStorage.removeItem('passwordCheck')
        store.dispatch(setLogoutStatus());
        <Login />;
      }

      throw error;
    }
  );

  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}
