import axios from 'axios';

 function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export const worklistApi = (limit, offset)=>(

  axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/worklist?limit=${limit}&offset=${offset}`)
        .catch((error)=> error)
)

export const worklistApiWithFilters = (filterPatientId, filterPatientAge,filterPatientGender,filterPatientNSID, limit, offset)=>(

  axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/worklist?patientId=${filterPatientId}&age=${filterPatientAge}&gender=${filterPatientGender.toLowerCase()}&nsid=${filterPatientNSID}&limit=${limit}&offset=${offset}`)
        .catch((error)=> error)
)

