import { useMemo } from 'react';
import { Link } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Container, Typography, Grid } from '@material-ui/core';
// layouts
import AuthLayout from '../../../layouts/AuthLayout';
// components
import Page from '../../../components/Page';
import { MHidden } from '../componenets/@material-extend';
import { RegisterForm } from './index';
import backImage from '../../../assests/Images/MicrosoftTeams-image (1).png';
import neurostudio from '../../../assests/Images/INMED AI STUDIO final-01 1.png';
import leftBackImg from '../../../assests/Images/leftbackgroud.png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';
// ----------------------------------------------------------------------

const RootStyle = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: '20px',
  gridTemplateColumns: `repeat(2, 1fr)`,
  height: '100%'
  // overflow: 'hidden'
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled(Grid)(({ theme }) => ({
  padding: 40,
  width: '45%'
  // backgroundSize: 'contain',
  // backgroundRepeat: 'no-repeat',
  // [theme.breakpoints.up('xs')]: {
  //   minHeight: 'calc(100% - 360px)',
  //   justifyContent: 'flex-start',
  //   // alignItems: 'center',
  //   padding: theme.spacing(10, 6, 10, 6)
  // },
  // [theme.breakpoints.up('md')]: {
  //   minHeight: 'calc(100% - 275px)',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   padding: theme.spacing(0, 12, 0, 12)
  // },
  // [theme.breakpoints.up('lg')]: {
  //   padding: theme.spacing(0, 24, 0, 24)
  // }
}));

const styles = {
  container: {
    // '@media (max-width: 768px)': {
    //   marginTop: '10px'
    // },
    // '@media (min-width: 768px) and (max-width: 1534px)': {
    //   marginTop: '50px'
    //   // extra small screen register come up
    // },
    // '@media (min-width: 1535px) and (max-width: 3260px)': {
    //   marginTop: '150px'
    // }
  },
  studioimg: {
    // '@media (min-width: 1235px) and (max-width: 3260px)': {
    //   width: '400px'
    // }
  },
  box: {
    // '@media (min-width: 768px) and (max-width: 1244px)': {
    //   width: '30%'
    // }
  },
  formbox: {
    // '@media (min-width: 768px) and (max-width: 1534px)': {
    //   marginTop: '-90px'
    // }
  }
};

const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

// ----------------------------------------------------------------------

export default function Register() {
  const isSafari = useMemo(() => (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')), []);

  return (
    <RootStyle title="Register | Minimal-UI">
      <div style={{ position: 'relative' }}>
        <img
          alt=""
          src={leftBackImg}
          style={{ objectFit: 'fill', height: !isSafari ? '100%' : 'inherit',  marginRight: '10px' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: `translate(-50%, -50%)`
          }}
        >
          <img src={neuroStudioWhite} alt="" height="400" width="400px" />
        </div>
        <div style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}>
          {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
          <Box fontSize="18px">Version : 3.0</Box>
        </div>
      </div>

      <div style={{margin:'auto'}}>
        {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}

        <BoxContainer display="flex" justifyContent="center" sx={{ p: 3 }}>
          <Box ml={-10}>
          <Box
            display="flex"
            justifyContent="center"
            fontSize="27px"
            letterSpacing={1.5}
            fontWeight={900}
          >
            Inmed AI Studio Register
          </Box>

          <Box pt={2} pb={3}display="flex" justifyContent="center" fontSize="13px" color="gray">
            Welcome! Enter your credentials below.
          </Box>
          </Box>
          <RegisterForm />
          <Box padding={2} pl={4}>
            Already have an account ?{' '}
            <Link to="/login" style={{ textDecoration: 'none' }}>
              Login
            </Link>
          </Box>
        </BoxContainer>
      </div>
    </RootStyle>
  );
}
