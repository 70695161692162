import React, { useEffect } from 'react';

import { Box, Button, IconButton, InputAdornment, Modal, Snackbar, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Icon from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { useChangePassword } from './hooks';
import { modalStyle, textStyle, useStyles } from './styles';

const ChagePassword = () => {
  const {
    handleClose,
    vertical,
    open,
    horizontal,
    responseState,
    handleRequestClose,
    modalOpen,
    handleShowConfirmPassword,
    handleShowPassword,
    showConfirmPassword,
    showPassword,
    formik
  } = useChangePassword();
  const Classes = useStyles();
  const { handleSubmit, getFieldProps, errors, touched } = formik;

  return (
    <>
      <Snackbar
        severity="red"
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={responseState.msg}
        onClose={handleClose}
        onRequestClose={handleRequestClose}
        key={vertical + horizontal}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <Modal open={!modalOpen}>
        <div style={modalStyle} className={Classes.paper}>
          <FormikProvider value={formik}>
            {/* {({ handleSubmit, errors, ...rest }) => ( */}
            <>
              <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
                <Box my={1}>
                  <h3 style={{ margin: '0 0 10px 5px' }}>Change Password</h3>

                  <TextField
                    // className={classes.input}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="New Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />

                  <TextField
                    style={{ marginTop: '15px' }}
                    fullWidth
                    label="Confirm Password"
                    {...getFieldProps('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowConfirmPassword} edge="end">
                            <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />

                  <div style={{ marginTop: '10px' }}>
                    <Button type="submit" fullWidth variant="contained">
                      Update
                    </Button>
                  </div>
                </Box>
              </form>
            </>
            {/* )} */}
          </FormikProvider>
        </div>
      </Modal>
    </>
  );
};

export default ChagePassword;
