// material
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik, useFormik } from 'formik'; // <-- Import useFormik
import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { Link, useNavigate } from 'react-router-dom';
// layouts
import NewLogo from '../../../components/NewLogo';
// components
import bgImage from '../../../assests/Images/login-image.png';
import neuroShield from '../../../assests/Images/neuroshiiled-logo-2-3.jpg';

import backImage from '../../../assests/Images/MicrosoftTeams-image (1).png';
import neurostudio from '../../../assests/Images/INMED AI STUDIO final-01 1.png';
import leftBackImg from '../../../assests/Images/leftbackgroud.png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';
import inmedLogo from '../../../assests/Images/inmed-logo.png';
import { LoginForm } from '../login';

// ----------------------------------------------------------------------

const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: 40,
  width: '45%'
  // backgroundSize: 'contain',
  // backgroundRepeat: 'no-repeat',
  // [theme.breakpoints.up('xs')]: {
  //   minHeight: 'calc(100% - 360px)',
  //   justifyContent: 'flex-start',
  //   alignItems: 'start',
  //   padding: theme.spacing(10, 6, 10, 6)
  // },
  // [theme.breakpoints.up('md')]: {
  //   minHeight: 'calc(100% - 275px)',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   padding: theme.spacing(0, 12, 0, 12)
  //   // backgroundImage: `url(${bgImage})`
  // },
  // [theme.breakpoints.up('lg')]: {
  //   padding: theme.spacing(0, 24, 0, 24)
  // }
}));

const RouterLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none'
}));

const RootGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%'
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const validationSchema = Yup.object().shape({
  entityName: Yup.string().required('Organization Name is required') // Username is required
});



export default function RegionLogin() {
  const navigate = useNavigate();
  const [defaultCountry, setDefaultCountry] = useState(
    localStorage.getItem('selectedCountry') || 'US'
  );
  const formik = useFormik({
    initialValues: {
      entityName: '',
      selectedCountry:defaultCountry 
    },
    // eslint-disable-next-line object-shorthand
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { entityName, selectedCountry } = values;
      if (selectedCountry === 'India') {
        // navigate(`/login?username=${username}`);
        window.location = `${process.env.REACT_APP_UAT_APP_URL}/login?organizationName=${entityName}`;
      } else if (selectedCountry === 'US') {
        // window.location = `${process.env.REACT_APP_US_APP_URL}/login?username=${username}`;
        navigate(`/register?organizationName=${entityName}`);
      }
      localStorage.setItem('selectedCountry', defaultCountry);
    }
  });
  useEffect(() => {
    localStorage.setItem('selectedCountry', defaultCountry);
  }, [defaultCountry]);

  const handleCountryChange = (event) => {
    setDefaultCountry(event.target.value);
    formik.setFieldValue('selectedCountry', event.target.value);
  };


  const styles = {
    container: {
      '@media (max-width: 768px)': {
        marginTop: '10px'
      },
      '@media (min-width: 768px) and (max-width: 1534px)': {
        marginTop: '90px'
      },
      '@media (min-width: 1535px) and (max-width: 3260px)': {
        marginTop: '150px'
      }
    },
    studioimg: {
      '@media (min-width: 1235px) and (max-width: 3260px)': {
        width: '400px'
      }
    },
    box: {
      '@media (min-width: 768px) and (max-width: 1244px)': {
        width: '30%'
      }
    }
  };

  const isSafari = useMemo(() => (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')), []);
  return (
    <div
      style={{
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: `repeat(2, 1fr)`,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          alt=""
          src={leftBackImg}
          style={{ objectFit: 'fill', height: !isSafari ? '100%' : 'inherit',  marginRight: '10px' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: `translate(-50%, -50%)`
          }}
        >
          <img src={neuroStudioWhite} alt="" height="400" width="400px" />
        </div>
        <div style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}>
          {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
          <Box fontSize="18px">Version : 3.0</Box>
        </div>
      </div>

      <div style={{margin:'auto'}}>
        {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}
        <BoxContainer  display="flex" justifyContent="center" alignItems='center' sx={{ pb: 3, pr:'150px' }} mt={7}>
          <Box
            display="flex"
            // justifyContent="center"
            fontSize="27px"
            letterSpacing={1.5}
            fontWeight={900}
          >
            Inmed AI Studio Login
          </Box>
        </BoxContainer>

        <form style={{marginLeft:'-60px',marginRight:"60px"}} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Enter Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <Stack direction="row" alignItems="center" justifyContent="left" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Radio onChange={handleCountryChange} value="US" />}
              checked={formik.values.selectedCountry === 'US'}
              label="US"
            />
            <FormControlLabel
              control={<Radio onChange={handleCountryChange} value="India" />}
              checked={formik.values.selectedCountry === 'India'}
              label="India"
            />
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Next
          </LoadingButton>
        </form>

        <BoxContainer ml={-7} sx={{ pt: 2 }}>
          <Typography>
            Dont have an Account ?
            <RouterLink to={{ pathname: '/region-register' }}> Register</RouterLink>.
          </Typography>
        </BoxContainer>
      </div>
    </div>
  );
}
