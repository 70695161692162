import axios from 'axios';


export const fetchUsers = (id) =>(

    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/users?siteId=${id}`)
    .catch((error)=>(error))


)

