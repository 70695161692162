import { Box, Button, Snackbar, SnackbarContent, TextField, Typography } from '@material-ui/core';
import { Form, FormikProvider } from 'formik';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@material-ui/lab';
import { useForgotPassword } from './hooks';
import leftBackImg from '../../../assests/Images/leftbackgroud.png';
import neuroStudioWhite from '../../../assests/Images/INMED AI STUDIO final-02-Photoroom.png-Photoroom.png';

const ForgotPassword = () => {
  const { formik, responseState, vertical, horizontal, handleClose, classes, open } =
    useForgotPassword();

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  return (
    <div
      style={{
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: `repeat(2, 1fr)`,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          alt=""
          src={leftBackImg}
          style={{ objectFit: 'fill', height: '100%', marginRight: '10px' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: `translate(-50%, -50%)`
          }}
        >
          <img src={neuroStudioWhite} alt="" height="400" width="400px" />
        </div>
        <div style={{ position: 'absolute', color: 'white', bottom: '100px', marginLeft: '80px' }}>
          {/* <Box fontSize="19px">CDSCO Reg. No : Progno-Pune-MH/M/MD/001225</Box> */}
          <Box fontSize="18px">Version : 3.0</Box>
        </div>
      </div>

      <div style={{ margin: 'auto', width: '55%' }}>
        {/* <img style={{ margin: '0 0 0 auto' }} src={neurostudio} alt="" /> */}
        {/* <BoxContainer
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3, pr: '150px' }}
          mt={7}
        > */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="27px"
          p={3}
          letterSpacing={0.8}
          fontWeight={900}
        >
          Inmed AI Studio Forgot Password
        </Box>
        {/* </BoxContainer> */}

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={classes.form}>
            <Snackbar
              severity={responseState.error ? 'red' : 'green'}
              autoHideDuration={5000}
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              key={vertical + horizontal}
            >
              <SnackbarContent
                style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
                message={responseState.msg}
              />
            </Snackbar>

            <div className={classes.mainHeading}>
              {/* <img src={logo} alt="Neuro-Shield" width="300" /> */}
            </div>
            <div className={classes.formBody}>
              <Typography className={classes.para}>
                Enter your email and we will send you a mail with instruction to reset your password
              </Typography>
              <TextField
                className={classes.input}
                fullWidth
                placeholder="Username"
                name="username"
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />

              <TextField
                className={classes.input}
                fullWidth
                placeholder="Email"
                name="email"
                type="email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <br />
              <LoadingButton
                size="large"
                fullWidth
                className={classes.btn}
                variant="contained"
                color="primary"
                type="submit"
              >
                Send Reset Link
              </LoadingButton>
              <Typography className={classes.back}>
                Back to &nbsp;
                <Link to="/login">Login</Link>
              </Typography>
            </div>
          </Form>
        </FormikProvider>

        {/* <BoxContainer sx={{ pt: 2 }}>
          <Typography>
            Dont have an Account ?
            <RouterLink to={{ pathname: '/region-register' }}> Register</RouterLink>.
          </Typography>
        </BoxContainer> */}
      </div>
    </div>
  );
};

export default ForgotPassword;
