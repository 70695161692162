import React, { useState } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  Box,
  Button,
  FormHelperText,
  Radio,
  RadioGroup,
  SnackbarContent,
  Snackbar,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';

const UpdateStudy = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const { id } = useParams();
  const location = useLocation();
  const { age, gender } = location.state;
  const [study, setStudy] = useState({ age, gender });

  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const siteSchema = Yup.object().shape({
    patientAge: Yup.string().required('Required'),
    patientSex: Yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {
      patientAge: study.age,
      patientSex: study.gender
    },
    enableReinitialize: true,
    validationSchema: siteSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .patch(`${process.env.REACT_APP_API_SERVER_URL}/api/studies/${id}`, values)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: 'updated' });
          setStudy({ age: res.data.data.patientAge, gender: res.data.data.patientGender });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          resetForm(formik.initialValues);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/worklist">
            WorkList
          </Link>
          <Link color="textPrimary" to="/dashboard/worklist" aria-current="page">
            Edit Study
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="patientAge" style={marginStyles}>
          Age <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          type="number"
          name="patientAge"
          variant="outlined"
          label="Patient Age"
          fullWidth
          {...getFieldProps('patientAge')}
          style={{ width: '70%' }}
          helperText={touched.patientAge && errors.patientAge}
          error={Boolean(touched.patientAge && errors.patientAge)}
        />
        <Box mt={2}>
          <FormControl
            component="fieldset"
            error={Boolean(touched.patientSex && errors.patientSex)}
          >
            <FormLabel component="legend">
              Gender <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-label="patientSex"
              name="patientSex"
              {...getFieldProps('patientSex')}
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
            <FormHelperText error={Boolean(touched.patientSex && errors.patientSex)}>
              {Boolean(touched.patientSex && errors.patientSex) && 'Required'}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mt={3}>
          <Button type="reset" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Update{' '}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default UpdateStudy;
