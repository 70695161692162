import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Snackbar,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  SnackbarContent,
  Box,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setLoggedInUser } from '../../../redux/actions/authActions';
import { loginApi } from '../../../api/login/LoginApi';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';
import ChangePassword from './change-password';
import { useLogin } from './hook';
// import { setSnackbar } from '../../../redux/actions/SnackbarAction';

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const usernameParam = queryParams.get('username');
  // const [counter, setCounter] = useState(0);
  // const [showPassword, setShowPassword] = useState(false);
  // const [responseState, setResponseState] = useState({ error: false, msg: '' });
  // const [savedLoginData, setSavedLoginData] = useState({
  //   username: usernameParam || '',
  //   password: '',
  //   remember: true
  // });
  // const [savedData, setSavedData] = useState(false);
  // const dispatch = useDispatch();
  // const [changePassword,setChangePassword]=useState(false)
  // const [state, setState] = React.useState({
  //   open: false,
  //   vertical: 'top',
  //   horizontal: 'center'
  // });

  // const { vertical, horizontal, open } = state;

  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };

  // const LoginSchema = Yup.object().shape({
  //   username: Yup.string().required('Username is required'),
  //   password: Yup.string().required('Password is required')
  // });
  const {formik,changePassword,counter,location,navigate,queryParams,responseState,savedData,savedLoginData,showPassword,usernameParam,open,vertical,horizontal,handleClose,setSavedData,setSavedLoginData,setShowPassword}=useLogin()

  // useEffect(() => {
  //   const loginData = JSON.parse(localStorage.getItem('savedLoginCredentials'));
  //   if (loginData) {
  //     setSavedData(true);
  //     setSavedLoginData(loginData);
  //   } else {
  //     setSavedData(false);
  //     const obj = {
  //       username: usernameParam || '',
  //       password: '',
  //       remember: true
  //     };

  //     setSavedLoginData(obj);
  //   }
  // }, []);

  //   initialValues: {
  //     username: usernameParam || savedData ? savedLoginData.username : '',
  //     password: savedData && !usernameParam ? savedLoginData.password : '',
  //     remember: savedData ? savedLoginData.remember : true
  //   },
  //   validationSchema: LoginSchema,
  //   onSubmit: async (value) => {
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/login`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           username: value.username,
  //           password: value.password
  //         })
  //       });
  //       const resJson = await res.json();
  //       const firstLogin=resJson.data?.isFirstLogin
  //       console.log("check first",resJson.data?.isFirstLogin)

  //       if (res.status === 200) {
  //         setCounter(0);
  //         console.log(changePassword,"after 200");
  //         if (firstLogin) {
  //           setChangePassword(true); // Set changePassword to true if firstLogin is true
  //           console.log(changePassword,"after 200 and setting true");
  //         } 
  //           const loggedInUser = resJson;
  //           const { data: { sites = [] } = {} } = loggedInUser;
  //           const hasEnabledSitebleedSubscription = sites.find((site) => !!site.sitebleedSubscription);

  //           setAuthorizationToken(resJson.token);

  //           localStorage.setItem(
  //             'loggedInUser',
  //             JSON.stringify({
  //               ...loggedInUser,
  //               data: {
  //                 ...loggedInUser.data,
  //                 hasBleedSubscription: !!hasEnabledSitebleedSubscription
  //               }
  //             })
  //           );

  //           setResponseState({ success: true, msg: resJson.data.msg });

  //           dispatch(
  //             setLoggedInUser({
  //               ...loggedInUser,
  //               data: { ...loggedInUser.data, hasBleedSubscription: !!hasEnabledSitebleedSubscription }
  //             })
  //           );

  //           // eslint-disable-next-line no-extra-boolean-cast
  //           if (!!hasEnabledSitebleedSubscription) {
  //             navigate('/siteaccess', { replace: true });
  //           } else {
  //             navigate('/dashboard/app', { replace: true });
  //           }
  //         }
        

  //       if (res.status === 401) {
  //         setResponseState({ error: true, msg: 'Invalid Credentials' });

  //         setState({ open: true, vertical: 'top', horizontal: 'right' });
  //         setCounter(resJson.data.failedLoginAttempts);
  //         setTimeout(() => {
  //           setResponseState({ error: false, msg: '' });
  //         }, 3000);
  //       }
  //       if(res.status===403){
  //         console.log(resJson)
  //         setResponseState({ error: true, msg: 'Invalid Credentials' });
  //         setState({ open: true, vertical: 'top', horizontal: 'right' });

  //       }
  //     } catch (err) {
  //       setResponseState({ error: true, msg: err.data.msg });

  //       setState({ open: true, vertical: 'top', horizontal: 'right' });

  //       setTimeout(() => {
  //         setResponseState({ error: false, msg: '' });
  //       }, 3000);
  //     }
  //   }
  // });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  // useEffect(() => {
  //   const loginData = JSON.parse(localStorage.getItem('savedLoginCredentials'));
  //   if (loginData) {
  //     values.username = usernameParam || loginData.username;
  //     values.password = usernameParam ? '' : loginData.password;
  //     values.remember = loginData.remember;
  //   }
  // }, []);

  const saveLoginData = () => {
    const checkbox = document.getElementById('rememberCheckbox');
    if (checkbox.checked === true) {
      localStorage.setItem('savedLoginCredentials', JSON.stringify({ ...values, remember: true }));
    } else {
      localStorage.removeItem('savedLoginCredentials');
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  // const {
  //   formik,
  //   vertical,
  //   horizontal,
  //   open,
  //   handleClose,
  //   handleShowPassword,
  //   responseState,
  //   showPassword,
  //   counter
  // } = useLogin();
  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
        </Snackbar>

        <Stack margin='auto' width={counter?"50%":null} mb={3} spacing={3}>
          <TextField
            fullWidth
            // disabled={!!usernameParam}
            autoComplete="username"
            type="text"
            label="Enter Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Enter Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        {counter >0?
          <Stack>
            <Typography  variant="body2" align="center" color="error">
              You have made {counter} unsuccessful attempt(s). Your account will be locked after 15
              unsuccessful attempts.
            </Typography>
          </Stack>
        :<></>}

        <Stack width={counter?"50%":null} direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={
              <Checkbox
                id="rememberCheckbox"
                onClick={saveLoginData}
                {...getFieldProps('remember')}
                checked={values.remember}
              />
            }
            label="Remember me"
          /> */}
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack>

        <LoadingButton 
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          width={counter?"50%":null}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
