// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import  jwt from 'jwt-decode';
import axios from 'axios';

// redux
import {Provider} from "react-redux";
import store from './redux/store';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

import setAuthorizationToken from "./utils/setAuthorizationToken";
import { setLoggedInUser,setLogoutStatus } from './redux/actions/authActions';

const currentUserApi = async () => {
  axios
    .get(`${process.env.REACT_APP_API_SERVER_URL}/api/users/currentUser/data`)
    .then((res) => {
      const { passcode } = JSON.parse(localStorage.loggedInUser) || {};
      localStorage.setItem('loggedInUser', JSON.stringify({ ...res.data, passcode }));

      const { data: { sites = [] } = {} } = res.data;

      const hasEnabledSitebleedSubscription = sites.find((site) => !!site.sitebleedSubscription);

      store.dispatch(
        setLoggedInUser({
          ...res.data,
          data: { ...res.data.data, sitebleedSubscription: !!hasEnabledSitebleedSubscription }
        })
      );
    })
    .catch((err) => {
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('passwordCheck')
      store.dispatch(setLogoutStatus());
    });
};


if (localStorage.loggedInUser) {
  const isTokenExpired = jwt(JSON.parse(localStorage.loggedInUser).token);
  const currentDate = new Date();

  if (isTokenExpired.exp * 1000 < currentDate.getTime()) {
    setAuthorizationToken();
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem('passwordCheck')
    store.dispatch(setLogoutStatus());
  } else {
    setAuthorizationToken(JSON.parse(localStorage.loggedInUser).token);
    store.dispatch(setLoggedInUser(JSON.parse(localStorage.loggedInUser)))
    currentUserApi()
  }
}

ReactDOM.render(
  <Provider store={store}>
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
