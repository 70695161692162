import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Breadcrumbs, TextField, InputLabel, SnackbarContent, Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { fetchSite } from '../../api/siteList/fetchSite';

const SiteReport = () => {
  const siteGetId = useSelector((state) => state.editUser.userId);

  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const [entityName, setEntityName] = useState('');
  const [editUserForm, setEditUserForm] = useState({});
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const siteFetch = async () => {
      const res = await fetchSite(siteGetId);
      let entity;
      if (res?.data?.data?.entityId) {
        entity = await axios.get(
          `${process.env.REACT_APP_API_SERVER_URL}/api/entities/${res.data.data?.entityId}`
        );
      }

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setEditUserForm(res.data.data);
          setEntityName(entity?.data.data.name);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    siteFetch();
  }, [siteGetId]);

  const formik = useFormik({
    initialValues: {
      siteName: editUserForm.name,
      siteShortName: editUserForm.shortName,
      siteAddress: editUserForm.address,
      siteEntityId: editUserForm.entityId
    },
    enableReinitialize: true
  });

  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/sitelist">
            Sites
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="siteName" style={marginStyles}>
          Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          disabled
          name="siteName"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.name}
        />

        <InputLabel htmlFor="siteShortName" style={marginStyles}>
          Short Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          disabled
          name="siteShortName"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.shortName}
        />

        <InputLabel htmlFor="siteAddress" style={marginStyles}>
          Address <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          disabled
          name="siteAddress"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.address}
        />

        <InputLabel htmlFor="institutionName" style={marginStyles}>
          Institution Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          disabled
          name="institutionName"
          variant="outlined"
          style={{ width: '70%' }}
          value={editUserForm.institutionName}
        />

        <InputLabel htmlFor="Entity Name" style={marginStyles}>
          Entity Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          disabled
          name="Entity Name"
          variant="outlined"
          style={{ width: '70%' }}
          value={entityName}
        />
      </Form>
    </FormikProvider>
  );
};

export default SiteReport;
