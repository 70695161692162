import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  SnackbarContent,
  Snackbar,
  Grid
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { fetchUser } from '../../api/userList/fetchUser';
import { fetchSites } from '../../api/uploadStudy/fetchSites';
import { fetchRoles } from '../../api/userList/addUser/fetchRoles';

const UserReport = () => {
  const edituserId = useSelector((state) => state.editUser.userId);

  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const [sites, setSites] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editUserForm, setEditUserForm] = useState({});
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const fetchEditUser = async () => {
      const res = await fetchUser(edituserId);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 201) {
          setEditUserForm(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchEditUser();
  }, [edituserId]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchRoles();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setRoles(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchData();
  }, []);

  const roleName = roles.filter((role) => role._id === editUserForm.role);

  const siteNameArray = [];

  for (let i = 0; i < sites.length; i += 1) {
    if (editUserForm.sites === undefined) {
      siteNameArray.push(' ');
    } else if (editUserForm.sites.includes(sites[i]._id)) {
      siteNameArray.push(sites[i].name);
    }
  }

  const formik = useFormik({
    initialValues: {
      username: editUserForm.username,
      firstname: editUserForm.firstname,
      lastname: editUserForm.lastname,
      email: editUserForm.email,
      phone: editUserForm.phone,
      sites: editUserForm.sites,
      role: ''
    },
    enableReinitialize: true
  });

  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/users">
            Users
          </Link>
        </Breadcrumbs>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="firstname" style={marginStyles}>
              First Name
            </InputLabel>
            <TextField
              disabled
              name="firstname"
              variant="outlined"
              fullWidth
              value={editUserForm.firstname}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="lastname" style={marginStyles}>
              Last Name
            </InputLabel>
            <TextField
              disabled
              name="lastname"
              variant="outlined"
              fullWidth
              value={editUserForm.lastname}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="email" style={marginStyles}>
              Email <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              disabled
              name="email"
              variant="outlined"
              fullWidth
              value={editUserForm.email}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="phone" style={marginStyles}>
              Mobile Number
            </InputLabel>
            <TextField
              disabled
              name="phone"
              variant="outlined"
              fullWidth
              value={editUserForm.phone}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="username" style={marginStyles}>
              Username <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              disabled
              name="username"
              variant="outlined"
              fullWidth
              value={editUserForm.username}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="sites" style={marginStyles}>
              Sites
            </InputLabel>
            <TextField disabled name="sites" variant="outlined" fullWidth value={siteNameArray} />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="role" style={marginStyles}>
              Role <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              disabled
              name="role"
              variant="outlined"
              fullWidth
              value={roleName[0] ? roleName[0].name : 'No Role'}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default UserReport;
