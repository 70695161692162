import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
  Box,
  Button,
  MenuItem,
  SnackbarContent,
  Snackbar,
  Grid,
  Select,
  FormControl,
  Input,
  Chip
} from '@material-ui/core';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { fetchSites } from '../../api/uploadStudy/fetchSites';
import { fetchRoles } from '../../api/userList/addUser/fetchRoles';

const AddUser = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const [sites, setSites] = useState([]);
  const [siteId, setSiteId] = useState('');
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchRoles();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setRoles(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchData();
  }, []);

  const handleSiteId = (id) => {
    setSiteId(id);
  };

  const userSchema = Yup.object().shape({
    username: Yup.string()
      .required('Required'),
     // .matches(/^[A-Za-z]*$/, 'Username should not contain any space.'),
    password: Yup.string()
      .min(8, 'Password should contain minimum 8 characters')
      .matches(/^(?=.*[A-Z]).*$/, 'Password should contain at least one capital letter')
      .matches(/^(?=.*[@$!%*#?&]).*$/, 'Password should contain at least one special character')
      .matches(/^(?=(?:\D*\d){2}).*$/, 'Password should contain at least two digits')
      .matches(/^(?!.* ).*$/, 'Password should not contain spaces')
      // .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
      .required('Required'),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
    sites: Yup.array().min(1, 'Site required').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    role: Yup.string().required('Required'),
    phone: Yup.string()
      .min(10, 'Please enter valid 10-digit number')
      .max(10, 'Please enter valid 10-digit number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Required'
      )
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      sites: '',
      role: ''
    },
    validationSchema: userSchema,
    onSubmit: async (value, { resetForm }) => {
      value.sites = siteId;

      const res = await axios
        .post(`${process.env.REACT_APP_API_SERVER_URL}/api/users`, value)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 201) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          resetForm(formik.initialValues);
          setSelectedSites([]);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/users">
            Users
          </Link>
          <Link color="textPrimary" to="/dashboard/users/add-user" aria-current="page">
            Add User
          </Link>
        </Breadcrumbs>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="firstname" style={marginStyles}>
              First Name
            </InputLabel>
            <TextField
              name="firstname"
              variant="outlined"
              label="First Name"
              fullWidth
              {...getFieldProps('firstname')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="lastname" style={marginStyles}>
              Last Name
            </InputLabel>
            <TextField
              name="lastname"
              variant="outlined"
              label="Last Name"
              fullWidth
              {...getFieldProps('lastname')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="email" style={marginStyles}>
              Email <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="email"
              variant="outlined"
              label="Enter Email"
              fullWidth
              {...getFieldProps('email')}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="phone" style={marginStyles}>
              Mobile Number
            </InputLabel>
            <TextField
              name="phone"
              variant="outlined"
              label="Enter Mobile Number"
              fullWidth
              {...getFieldProps('phone')}
              helperText={touched.phone && errors.phone}
              error={Boolean(touched.phone && errors.phone)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="username" style={marginStyles}>
              Username <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              name="username"
              variant="outlined"
              label="Enter Username"
              fullWidth
              {...getFieldProps('username')}
              helperText={touched.username && errors.username}
              error={Boolean(touched.username && errors.username)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="password" style={marginStyles}>
              Password <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="password"
              variant="outlined"
              label="Enter Password"
              fullWidth
              {...getFieldProps('password')}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="confirmPassword" style={marginStyles}>
              Confirm Password <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="confirmPassword"
              variant="outlined"
              label="Re-Enter Password"
              fullWidth
              {...getFieldProps('confirmPassword')}
              InputAdornment={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={touched.confirmPassword && errors.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="sites" style={marginStyles}>
              Sites <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="sites"> Select Sites</InputLabel>
              <Select
                name="sites"
                multiple
                value={selectedSites}
                onChange={(e) => {
                  setFieldValue('sites', e.target.value);
                  setSelectedSites(e.target.value);
                }}
                input={<Input />}
                labelId="sites"
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: '0px 2px' }} />
                    ))}
                  </div>
                )}
                style={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  padding: '10px 0'
                }}
              >
                <MenuItem disabled>Select Sites</MenuItem>
                {sites.map((site) => (
                  <MenuItem
                    key={site._id}
                    value={site.name}
                    onClick={() => {
                      handleSiteId(site._id);
                    }}
                  >
                    {site.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.sites && <p style={{ color: 'red' }}>Required</p>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '70%' }}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="role" style={marginStyles}>
              Role <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              select
              name="role"
              variant="outlined"
              label="Select Role"
              fullWidth
              {...getFieldProps('role')}
              helperText={touched.role && errors.role}
              error={Boolean(touched.role && errors.role)}
            >
              <MenuItem value="Select Role" disabled>
                Select Role
              </MenuItem>
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Button
            type="reset"
            variant="contained"
            color="primary"
            style={{ marginRight: '20px' }}
            onClick={() => setSelectedSites([])}
          >
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Create{' '}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default AddUser;
