import React, { useEffect, useRef, useState } from 'react';
import {
  InputLabel,
  TextField,
  Breadcrumbs,
  Snackbar,
  SnackbarContent,
  Button,
  Box,
  RadioGroup,
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  MenuItem
} from '@material-ui/core';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

import { fetchSites } from '../../../api/uploadStudy/fetchSites';
import './input.css';
import UploadStudyModal from './UploadStudyModal';
import UploadProgress from './UploadProgress';

function UploadStudy() {
  const [sites, setSites] = useState([]);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [progress, setProgress] = React.useState(false);
  const [caseFiles, setCaseFiles] = React.useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const dropZoneRef = useRef(null);
  const fileRef = useRef(null);

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
        }
      }
    };
    fetchData();
  }, []);

  const [symptoms, setSymptoms] = React.useState([]);

  const marginStyles = {
    marginTop: '15px',
    marginBottom: '10px'
  };

  const uploadSchema = Yup.object().shape({
    siteId: Yup.string().required('Please Select Site.'),
    patientAge: Yup.number()
      .min(22, 'Age should not be below 22')
      .max(99, 'Age should not be greater than 99')
      .required('Please Enter Age.'),
    patientSex: Yup.string().required('Required'),
    ethnicity: Yup.string().required('Ethnicity Required')
    // caseFiles: Yup.mixed().test(
    //   'file presence validation',
    //   'No files chosen',
    //   (value) => Array.from(value).length > 0
    // )
    // // .test(
    // //   'file length validation',
    // //   'Number of Files must not exceed 500',
    // //   (value) => Array.from(value).length < 500
    // // )
    // .test(
    //   'file format validation',
    //   'Invalid file format. Please upload DICOM files only.',
    //   (value) => {
    //     if (!value) return true; // Return true if no files are selected
    //     const allowedFormats = ['dicom', 'dcm'];
    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i < value.length; i++) {
    //       const fileExtension = value[i].name.split('.').pop().toLowerCase();
    //       if (!allowedFormats.includes(fileExtension)) {
    //         return false; // Return false if any file has an invalid extension
    //       }
    //     }
    //     return true; // All files have valid extensions
    //   }
    // ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      siteId: '',
      patientAge: '',
      patientSex: '',
      symptoms: '',
      condition: '',
      ethnicity: '',
      refPhysician: '',
      caseFiles: []
    },
    validationSchema: uploadSchema,
    onSubmit: async (value, { resetForm }) => {
      try {
        const newStudy = new FormData();
        newStudy.append('siteId', value.siteId);
        newStudy.append('patientAge', value.patientAge);
        newStudy.append('patientSex', value.patientSex);
        newStudy.append('condition', value.condition);
        newStudy.append('ethnicity', value.ethnicity);
        newStudy.append('symptoms', JSON.stringify(symptoms && symptoms.map((symptom) => symptom)));
        newStudy.append('refPhysician', value.refPhysician);
        newStudy.append('UUID', uuidv4());
        if (caseFiles.length > 500) {
          throw new Error('Number of Files must not exceed 500');
        }
        Array.from(caseFiles).forEach((file) => {
          if (file.size / 1024 / 1024 > 25) {
            throw new Error('File size must not exceed 25 MB');
          }

          newStudy.append('caseFiles', file);
        });
        const res = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/studies/upload-study`,
          newStudy,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              setProgress(Math.round((loaded * 100) / total));
            }
          }
        );
        setResponseState({ error: false, msg: res.data.message });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
        resetForm(formik.initialValues);
        setProgress(false);
        setModalMessage(
          `${res.data.message}. ${
            res.data.isSuccess ? 'The case is being processed and will take some time.' : ''
          }`
        );
        setOpenModal(true);

        setSymptoms([]);
        setCaseFiles('');
      } catch (error) {
        setProgress(false);
        if (error.message) {
          setResponseState({ error: true, msg: error.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
        setResponseState({ error: true, msg: error.response.data.msg });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
        setModalMessage(error?.msg);
        setOpenModal(true);
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleModalClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
    setModalMessage('');
    setOpenModal(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    setFieldValue('caseFiles', files);
    setCaseFiles(files);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/worklist">
            WorkList
          </Link>
          <Link color="textPrimary" to="/dashboard/worklist/upload" aria-current="page">
            Upload Study
          </Link>
        </Breadcrumbs>

        <h2>Upload Patient Details</h2>
        <InputLabel htmlFor="siteId" style={marginStyles}>
          Site <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          select
          name="siteId"
          variant="outlined"
          label="Select Site"
          fullWidth
          {...getFieldProps('siteId')}
          style={{ width: '70%' }}
          helperText={touched.siteId && errors.siteId}
          error={Boolean(touched.siteId && errors.siteId)}
        >
          <MenuItem value="Select Site" disabled>
            Select Site
          </MenuItem>
          {sites.map((site) => (
            <MenuItem key={site._id} value={site._id}>
              {site.name}
            </MenuItem>
          ))}
        </TextField>

        <InputLabel htmlFor="patientAge" style={marginStyles}>
          Age <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          type="number"
          name="patientAge"
          variant="outlined"
          onWheel={(e) => e.target.blur()}
          label="Patient Age"
          fullWidth
          {...getFieldProps('patientAge')}
          style={{ width: '70%' }}
          helperText={touched.patientAge && errors.patientAge}
          error={Boolean(touched.patientAge && errors.patientAge)}
        />

        <Box mt={2}>
          <FormControl
            component="fieldset"
            error={Boolean(touched.patientSex && errors.patientSex)}
          >
            <FormLabel component="legend">
              Gender <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-label="patientSex"
              name="patientSex"
              {...getFieldProps('patientSex')}
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
            <FormHelperText error={Boolean(touched.patientSex && errors.patientSex)}>
              {Boolean(touched.patientSex && errors.patientSex) && 'Required'}
            </FormHelperText>
          </FormControl>
        </Box>

        {/* <Box mt={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Condition</FormLabel>
            <RadioGroup row aria-label="condition" name="condition" {...getFieldProps('condition')}>
              <FormControlLabel value="Epilepsy" control={<Radio />} label="Epilepsy" />
              <FormControlLabel value="Dementia" control={<Radio />} label="Dementia" />
              <FormControlLabel value="Alzeimers" control={<Radio />} label="Alzheimer's" />
              <FormControlLabel value="Parkinsons" control={<Radio />} label="Parkinsons" />
              <FormControlLabel value="MCI" control={<Radio />} label="MCI" />
            </RadioGroup>
          </FormControl>
        </Box> */}

        {/* <InputLabel htmlFor="symptoms" style={marginStyles}>
          Symptoms
        </InputLabel>
        <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor="symptoms"> Select Symptoms</InputLabel>
          <Select
            name="symptoms"
            multiple
            value={symptoms}
            onChange={handleChange}
            input={<Input />}
            labelId="symptom"
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={value} style={{ margin: '0px 2px' }} />
                ))}
              </div>
            )}
            style={{
              width: '70%',
              border: '1px solid lightgrey',
              borderRadius: '5px',
              padding: '10px 0'
            }}
          >
            {symptomsList.map((symptom) => (
              <MenuItem key={symptom} value={symptom}>
                {symptom}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <InputLabel htmlFor="ethncity" style={marginStyles}>
          Ethnicity <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          select
          name="ethnicity"
          variant="outlined"
          label="Select Ethnicity"
          style={{ width: '70%' }}
          {...getFieldProps('ethnicity')}
          helperText={touched.ethnicity && errors.ethnicity}
          error={Boolean(touched.ethnicity && errors.ethnicity)}
        >
          <MenuItem value="south_asian">South Asian</MenuItem>
          <MenuItem value="Caucasian">Caucasian</MenuItem>
        </TextField>

        <InputLabel htmlFor="refPhysician" style={marginStyles}>
          Ref Physician
        </InputLabel>
        <TextField
          name="refPhysician"
          variant="outlined"
          label="Referring Physician"
          style={{ width: '70%' }}
          {...getFieldProps('refPhysician')}
        />
        <div ref={dropZoneRef} onDrop={handleDrop} onDragOver={handleDragOver}>
          <InputLabel htmlFor="caseFiles" style={marginStyles}>
            Upload Dicom Files or Zip File <span style={{ color: 'red' }}>*</span>
          </InputLabel>

          <input
            id="caseFiles"
            ref={fileRef}
            name="caseFiles"
            hidden
            type="file"
            multiple
            accept="*/dicom, .dcm, image/dcm, */dcm, .dicom, .zip"
            className="input"
            onChange={(e) => {
              setFieldValue('caseFiles', e.currentTarget.files);
              setCaseFiles(e.currentTarget.files);
            }}
          />
          <Box>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                fileRef.current.click();
              }}
              style={{ marginRight: '20px' }}
            >
              Drag & Drop or Choose Files
            </Button>
            <span>
              {caseFiles.length ? `${caseFiles.length} files selected` : 'No file chosen'}
            </span>
          </Box>
          <FormHelperText error={Boolean(errors.caseFiles)}>{errors.caseFiles}</FormHelperText>
        </div>
        <Box mt={3}>
          {progress ? (
            <UploadProgress open={progress} onClose={!progress} progress={progress} />
          ) : (
            <></>
          )}
        </Box>

        <Box mt={3}>
          <Button
            type="reset"
            variant="contained"
            color="primary"
            style={{ marginRight: '20px' }}
            onClick={() => {
              setSymptoms([]);
            }}
          >
            {' '}
            Reset{' '}
          </Button>
          <LoadingButton loading={!!progress} variant="contained" color="primary" type="submit">
            {' '}
            Upload{' '}
          </LoadingButton>
        </Box>
        {!!openModal && (
          <UploadStudyModal
            open={openModal}
            onClose={handleModalClose}
            modalMessage={modalMessage}
          />
        )}
      </Form>
    </FormikProvider>
  );
}

//
export default UploadStudy;
