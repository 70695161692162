/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  InputBase,
  Box,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';

const WorklistRepBVA = ({ getFieldProps, edit, ethnicity, age }) => {
  const [responseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  // const dataStyles = {
  //   color: 'red',
  //   fontWeight: 'bold',
  //   padding: '10px 16px 10px 10px'
  // };

  const cellStyle = {
    '& th': {
      padding: '8px 16px 8px 10px'
    },
    '& td': {
      padding: '8px 16px 8px 10px'
    }
  };

  const cardStyle = {
    width: '100%',
    marginTop: '20px'
  };
  const inputStyle = {
    border: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    borderRadius: '8px',
    '& input': {
      marginLeft: '8px'
    }
  };

  const BVA_DATA = [
    // { id:1, structure: 'ICV volume', refid:21, prcntid:1, colorid:41 },
    // { id:2, structure: 'Whole brain', refid:22, prcntid:32, colorid:42 },
    // { id:21, structure: 'Ventricles', refid:221, prcntid:321, colorid:421},
    { id: 25, structure: 'Left Hippocampus', refid: 225, prcntid: 325, colorid: 425 },
    { id: 26, structure: 'Right Hippocampus', refid: 226, prcntid: 326, colorid: 426 },
    // { id:33, structure: 'Mid brain', refid:233, prcntid: 333, colorid:433},
    // { id:34, structure: 'Pons', refid:234, prcntid: 334, colorid:434},
    // { id:35, structure: 'Brain stem', refid:235, prcntid: 335, colorid:435},
    // { id:36, structure: 'Right Amygdala', refid:236, prcntid:336, colorid:436},
    // { id:37, structure: 'Left Amygdala', refid:237, prcntid:337, colorid:437},
    // { id:38, structure: 'Total Amygdala', refid:238, prcntid:338, colorid:438},
    // { id:39, structure: 'Right Caudate Nucleus', refid:239, prcntid:339, colorid:439},
    // { id:40, structure: 'Left Caudate Nucleus', refid:240, prcntid:340, colorid:440},
    // { id:41, structure: 'Total Caudate Nucleus', refid:241, prcntid:341, colorid:441},
    // { id:42, structure: 'Right Cerebellum', refid:242, prcntid:342, colorid:442},
    // { id:43, structure: 'Left Cerebellum', refid:243, prcntid:343, colorid:443},
    // { id:44, structure: 'TotalCerebellum', refid:244, prcntid:344, colorid:444},
    // { id:45, structure: 'Right Cerebrum ', refid:245, prcntid:345, colorid:445},
    // { id:46, structure: 'Left Cerebrum', refid:246, prcntid:346, colorid:446},
    // { id:47, structure: 'Total Cerebrum', refid:247, prcntid:347, colorid:447},
    // { id:48, structure: 'Right Globus Pallidus', refid:248, prcntid:348, colorid:448},
    // { id:49, structure: 'Left Globus Pallidus', refid:249, prcntid:349, colorid:449},
    // { id:50, structure: 'Total Globus Pallidus', refid:250, prcntid:350, colorid:450},
    // { id:51, structure: 'Grey Matter', refid:251, prcntid:351, colorid:451},
    // { id:52, structure: 'Right Putamen', refid:252, prcntid:352, colorid:452},
    // { id:53, structure: 'Left Putamen', refid:253, prcntid:353, colorid:453},
    // { id:54, structure: 'Total Putamen', refid:254, prcntid:354, colorid:454},
    // { id:55, structure: 'Right Thalamus', refid:255, prcntid:355, colorid:455},
    // { id:56, structure: 'Left Thalamus', refid:256, prcntid:356, colorid:456},
    // { id:57, structure: 'Total Thalamus', refid:257, prcntid:357, colorid:457},
    // { id:58, structure: 'White Matter', refid:258, prcntid:358, colorid:458},
    { id: 59, structure: 'Total Hippocampus', refid: 259, prcntid: 359, colorid: 459 }
  ];

  // let showRef = true;
  if (ethnicity === 'Indian' && (age < 11 || age > 80)) {
    // showRef = false;
  } else if (ethnicity === 'Caucasian' && (age < 50 || age > 80)) {
    // showRef = false;
  } else {
    // showRef = true;
  }

  return (
    <div>
      <div>
        <br />
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            message={responseState.msg}
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
          />
        </Snackbar>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <h3>Brain Volumetric Analysis</h3>
        </Box>
        <Card sx={cardStyle}>
          <TableContainer>
            <Table sx={cellStyle}>
              <TableHead>
                <TableRow>
                  <TableCell> Structure </TableCell>
                  <TableCell> Volume (ml) </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BVA_DATA.map((row) => {
                  const { id, structure, refid, prcntid } = row;
                  const vol = getFieldProps(`bva1${id}`);
                  const ref = getFieldProps(`bva${refid}`);
                  const icv = prcntid === 1 ? { value: 100 } : getFieldProps(`bva${prcntid}`);
                  let color = getFieldProps(`colorbva1${id}`) || 'red';

                  const [start, end] =
                    ref.value === undefined
                      ? [0, 0]
                      : ref.value.split('-').map((ele) => parseFloat(ele));
                  const icvNumberValue = parseFloat(icv.value);
                  let colorStyle;
                  if (icvNumberValue >= start) {
                    colorStyle = color.value || 'black';
                  } else if (parseInt(icvNumberValue * 10, 10) === parseInt(start * 10, 10)) {
                    colorStyle = color.value || 'blue';
                  } else if (parseInt(icvNumberValue * 10, 10) < parseInt(start * 10, 10)) {
                    colorStyle = color.value || 'red';
                  } else {
                    colorStyle = color.value || 'black';
                  }

                  if (id === 21 && (icvNumberValue < start || icvNumberValue > end)) {
                    colorStyle = 'red';
                  }

                  color = { ...color, value: colorStyle };
                  return (
                    <TableRow key={id}>
                      <TableCell> {structure} </TableCell>
                      <TableCell>
                        {edit ? <InputBase sx={inputStyle} {...vol} /> : vol?.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>

      {/* <div>
        <br />
        <h3>Volumetric Derived Analysis</h3>
        <Card sx={cardStyle}>
          <TableContainer>
            <Table sx={cellStyle}>
              <TableHead>
                <TableRow>
                  <TableCell> Analysis </TableCell>
                  <TableCell> Output </TableCell>
                  <TableCell> Reference </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {VDA_DATA.map((row) => {
                  const { id, analysis } = row;
                  const output = getFieldProps(`vda1${id}`);
                  const reference = getFieldProps(`vda2${id}`);
                  const color = getFieldProps(`colorvda1`);
                  const colorStyle = id === 1 ? color.value : 'black';
                  return (
                    <TableRow key={id}>
                      <TableCell>{analysis}</TableCell>
                      <TableCell sx={{ ...dataStyles, color: colorStyle }}>
                        {edit ? <InputBase sx={inputStyle} {...output} /> : output.value}
                      </TableCell>
                      {showRef && (
                        <TableCell>
                          {edit ? <InputBase sx={inputStyle} {...reference} /> : reference.value}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div> */}
      {/* <div>
        <br />
        <h3>Additional Analysis</h3>
        <Card sx={inputStyle}>
          {edit ? (
            <InputBase fullWidth label="fullWidth" id="fullWidth" {...addComment} />
          ) : (
            addComment.value || 'NA'
          )}
        </Card>
        <h3>Output</h3>
        <Card sx={inputStyle}>
          {edit ? (
            <InputBase fullWidth label="fullWidth" id="fullWidth" {...output} />
          ) : (
            output.value || 'NA'
          )}
        </Card>
        <h3>MRPI</h3>
        <Card sx={inputStyle}>
          {edit ? (
            <InputBase fullWidth label="fullWidth" id="fullWidth" {...mrpi} />
          ) : (
            mrpi.value || 'NA'
          )}
        </Card>
      </div> */}
    </div>
  );
};

export default WorklistRepBVA;
