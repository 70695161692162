import { filter, truncate } from 'lodash';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import { Link as RouterLink } from 'react-router-dom';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';

// material
import {
  Box,
  Card,
  Table,
  Tooltip,
  Stack,
  Button,
  Snackbar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton
} from '@material-ui/core';
import { Visibility, TrendingUp, FileCopyTwoTone } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
// components
import Page from '../../../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound, { IsLoading } from '../../../components/SearchNotFound';
import { WorkListHead, WorkListToolbar } from './index';
import { worklistApi, worklistApiWithFilters } from '../../../api/worklist/worklistApi';
import { useLogin } from '../../authentication/login/hook';
import ChangePassword from '../../authentication/login/change-password';
import { useChangePassword } from '../../authentication/login/change-password/hooks';
// import updateWorklist from './update-worklist/UpdateStudy'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'patientId', label: 'Patient ID', alignRight: false },
  { id: 'patientName', label: 'Patient Name', alignRight: false },
  { id: 'UUID', label: 'NSID', alignRight: false },
  { id: 'patientGender', label: 'Gender', alignRight: false },
  { id: 'patientAge', label: 'Age', alignRight: false },
  { id: 'processingStatus', label: 'Current Status', alignRight: false },
  { id: 'source', label: 'Upload Source', alignRight: false },
  { id: 'uploadedDate', label: 'Uploaded Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

const TABLE_HEAD_NEW = [
  { id: 'patientId', label: 'Patient ID', alignRight: false },
  { id: 'UUID', label: 'NSID', alignRight: false },
  { id: 'patientGender', label: 'Gender', alignRight: false },
  { id: 'patientAge', label: 'Age', alignRight: false },
  { id: 'site', label: 'Site', alignRight: false },
  { id: 'processingStatus', label: 'Current Status', alignRight: false },
  { id: 'source', label: 'Upload Source', alignRight: false },
  { id: 'uploadedDate', label: 'Uploaded Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.patientId.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

// const processingStatusColor = {
//   complete : 'success',
//   inReview : 'info,
//  failed : 'error,
// processing : 'info'
// }
export default function WorkList() {
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('uploadedDate');
  const [isLoading, setIsLoading] = useState(false);
  const [filterPatientId, setFilterPatientId] = useState('');
  const [filterPatientAge, setFilterPatientAge] = useState('');
  const [filterPatientGender, setFilterPatientGender] = useState('');
  const [filterPatientNSID, setFilterPatientNSID] = useState('');
  const isfirstlogin=localStorage.getItem('passwordCheck')
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const {modalOpen,setModalOpen}=useChangePassword()

  const [USERLIST, setUSERLIST] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(0);
  // let isStudyNotFound;
  const [tableLength, setTableLength] = useState(0);
  // const [patientid, setPatientId] = useState('');
  const { user } = useSelector((state) => state.loggedInUser);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };
  const iconStyle = {
    backgroundColor: '#dcf2d8',
    color: '#4fa52e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'pointer'
  };

  const disableIconStyle = {
    backgroundColor: '#DCDCDC',
    color: '#4f4f4e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'not-allowed'
  };

  useEffect(()=>{
    if(isfirstlogin==='true'){
      setModalOpen(true)
    }
    else{
      setModalOpen(false)
    }
  },[isfirstlogin,modalOpen,setModalOpen])

  useEffect(() => {
    async function fetchApi() {
      setIsLoading(!isLoading);
      const res = await worklistApi(rowsPerPage, page);
      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          setUSERLIST([]);
        } else if (res.status === 200) {
          const data = res.data.data.studies;
          setTableLength(parseInt(res.data.data.total, 10));
          if (res.data.data.length === 0) {
            setUSERLIST([]);
            //  isStudyNotFound = true;
          } else {
            setUSERLIST(data);
            setIsLoading(false);
            //  isStudyNotFound = false;
          }
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          setUSERLIST([]);
        }
      }
    }
    fetchApi();
  }, [pageRefresh]);

  useEffect(() => {
    async function fetchApi() {
      setIsLoading(true);
      const res = await worklistApiWithFilters(
        filterPatientId,
        filterPatientAge,
        filterPatientGender,
        filterPatientNSID,
        rowsPerPage,
        page
      );
      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          setUSERLIST([]);
        } else if (res.status === 200) {
          const data = res.data.data.studies;
          setTableLength(parseInt(res.data.data.total, 10));
          if (res.data.data.length === 0) {
            setUSERLIST([]);
            setIsLoading(false);
            // isStudyNotFound = true;
          } else {
            setUSERLIST(data);
            setIsLoading(false);
            // isStudyNotFound = false;
          }
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          setUSERLIST([]);
        }
      }
    }
    fetchApi();
  }, [
    filterPatientId,
    filterPatientAge,
    filterPatientGender,
    filterPatientNSID,
    rowsPerPage,
    page
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (patientId) => {
  //   const selectedIndex = selected.indexOf(patientId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, patientId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByPatientId = (event) => {
    setFilterPatientId(event.target.value.replace(/[&#,+()$~%.'":*?^<>{}]/g, ''));
  };

  const handleFilterByPatientAge = (event) => {
    setFilterPatientAge(event.target.value.replace(/[&#,+()$~%.'":*?^<>{}]/g, ''));
  };

  const handleFilterByPatientGender = (event) => {
    setFilterPatientGender(event.target.value.replace(/[&#,+()$~%.'":*?^<>{}]/g, ''));
  };

  const handleFilterByPatientNSID = (event) => {
    setFilterPatientNSID(event.target.value.replace(/[&#,+()$~%.'":*?^<>{}]/g, ''));
  };

  const getDicomSignedUrls = (patientId, siteId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/studies/getSignedUrl/${siteId}/${patientId}`
      )
      .then((response) => {
        const timestamp = Date.now();
        localStorage.setItem(
          `urls_${siteId}_${patientId}_${timestamp}`,
          JSON.stringify(response.data.message)
        );
        window.open(
          `/papaya.html?sid=${siteId}&pid=${patientId}&source=web&timestamp=${timestamp}`,
          '_blank'
        );
      });
  };

  // const getStudyFiles = (patientId, siteId) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_SERVER_URL}/api/studies/getSignedUrl/${siteId}/${patientId}`
  //     )
  //     .then((response) => {
  //       const timestamp = Date.now();
  //       // localStorage.setItem(`urls_${siteId}_${patientId}_${timestamp}`, JSON.stringify(response.data.message));

  //       const zip = new JSZip();
  //       const folderName = `${patientId}_${siteId}_${timestamp}`;
  //       // const folder = zip.folder(folderName);

  //       const urlData = response.data.message;
  //       let count = 0;
  //       urlData.forEach((url) => {
  //         const name = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));

  //         JSZipUtils.getBinaryContent(url, (err, data) => {
  //           if (err) {
  //           }
  //           if (data) {
  //             zip.file(name, data, { binary: true });
  //           }
  //           count += 1;
  //           if (count === urlData.length) {
  //             zip
  //               .generateAsync({ type: 'blob' })
  //               .then((blob) => {
  //                 saveAs(blob, folderName);
  //                 // setDownloadingStatus(false)
  //               })
  //               .catch((e) => {
  //                 // setDownloadingStatus(false)
  //               });
  //           }
  //         });
  //       });
  //     });
  // };

  const handleCopyClick = async (patientId, e) => {
    try {
      await navigator.clipboard.writeText(patientId);
    } catch (err) {
      console.error('Failed to copy!');
    }
  };

  const handleRefreshClick = () => {
    window.location.reload();
    setPageRefresh(pageRefresh + 1);
  };

  const filteredStudies = applySortFilter(USERLIST, getComparator(order, orderBy), filterPatientId);
  let isStudyNotFound = true;
  let isLoading2 = false;
  if (!isLoading) {
    isStudyNotFound = filteredStudies.length === 0;
    isLoading2 = false;
  } else {
    isLoading2 = true;
    isStudyNotFound = false;
  }

  return (
    <>
   {isfirstlogin==='true'?<ChangePassword />:<></>}
   
    <Page title="WorkList | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Worklist
          </Typography>
          <Stack direction="row" justifyContent="felx-end">
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={handleRefreshClick}
              startIcon={<Icon icon={refreshFill} />}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/worklist/upload"
              startIcon={<Icon icon={plusFill} />}
            >
              Upload Study
            </Button>
          </Stack>
        </Stack>

        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          message={responseState.msg}
          onClose={handleClose}
          onRequestClose={handleRequestClose}
          key={vertical + horizontal}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        
        <Card>
          <WorkListToolbar
            numSelected={selected.length}
            filterPatientId={filterPatientId}
            onFilterPatientId={handleFilterByPatientId}
            filterPatientAge={filterPatientAge}
            onFilterPatientAge={handleFilterByPatientAge}
            filterPatientGender={filterPatientGender}
            onFilterPatientGender={handleFilterByPatientGender}
            filterPatientNSID={filterPatientNSID}
            onFilterPatientNSID={handleFilterByPatientNSID}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={user.role === 'User' ? TABLE_HEAD : TABLE_HEAD_NEW}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredStudies.map((row) => {
                    const {
                      _id,
                      patientId,
                      patientGender,
                      processingStatus,
                      UUID,
                      uploadedDate,
                      patientAge,
                      site,
                      source,
                      patientName,
                      ethnicity
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    const newPatientGender = patientGender === 'male' ? 'M' : 'F';
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* {
                            user.role==='Admin' && 
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={() => handleClick(_id)}
                              />
                            </TableCell>
                          } */}
                        {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={3}>
                              <Typography variant="subtitle2" noWrap>
                                {patientId.substring(0,50) || "NA"}
                              </Typography>
                            </Stack>
                          </TableCell> */}
                        <TableCell align="left" sx={{ width: '20%' }}>
                          <Stack
                            spacing={2}
                            direction="row"
                            sx={{ justifyContent: 'space-between' }}
                          >
                            <Tooltip title={patientId} arrow placement="bottom-end">
                              <span>{truncate(patientId, { length: '20' }) || 'NA'}</span>
                            </Tooltip>
                            <Tooltip title="Copy patientID" arrow>
                              <FileCopyTwoTone
                                sx={{ cursor: 'pointer' }}
                                variant="contained"
                                onClick={() => handleCopyClick(patientId)}
                              />
                            </Tooltip>
                          </Stack>
                        </TableCell>
                        {user.role === 'User' && (
                          <TableCell align="left">
                            {patientName.replace(/[^a-zA-Z ]/g, '')}
                          </TableCell>
                        )}
                        <TableCell align="left">{UUID || 'NA'}</TableCell>
                        <TableCell align="left">{newPatientGender || 'NA'}</TableCell>
                        <TableCell align="left">{patientAge || 'NA'}</TableCell>
                        {user.role === 'Admin' && (
                          <TableCell align="left">{site.name || 'NA'}</TableCell>
                        )}
                        <TableCell align="left">
                          {/* <Label
                            variant="default"
                            color={
                              (processingStatus === 'Failed' && 'error') ||
                              (processingStatus === 'Complete' && 'success') ||
                              (processingStatus === 'In Review' && 'info') ||
                              (processingStatus === 'Processing' && 'info') ||
                              (processingStatus === 'Cancelled' && 'error') ||
                              (processingStatus === 'Rejected' && 'error')
                            }
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label> */}
                          {processingStatus==='Failed'? <Label
                            variant="ghost"
                            color='error'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label>:null}
                          {processingStatus==='Complete'? <Label
                            variant="ghost"
                            color='success'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                            {console.log(sentenceCase(processingStatus || 'NA'))}
                          </Label>:null}
                          {processingStatus==='Complete 21 S'? <Label
                            variant="ghost"
                            color='success'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                            {console.log(sentenceCase(processingStatus || 'NA'))}
                          </Label>:null}
                          {processingStatus==='Uploading'? <Label
                            variant="ghost"
                            color='info'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                            {console.log(sentenceCase(processingStatus || 'NA'))}
                          </Label>:null}
                          {processingStatus==='In Review'? <Label
                            variant="ghost"
                            color='info'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label>:null}
                          {processingStatus==='Rejected'? <Label
                            variant="ghost"
                            color='error'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label>:null}
                          {processingStatus==='Processing'? <Label
                            variant="ghost"
                            color='info'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label>:null}
                          {processingStatus==='Cancelled'? <Label
                            variant="ghost"
                            color='error'
                          >
                            {sentenceCase(processingStatus || 'NA')}
                          </Label>:null}
                        </TableCell>
                        <TableCell align="left">{source || 'NA'}</TableCell>
                        <TableCell align="left">
                          {moment.utc(uploadedDate).format('MMM DD, YYYY, h:mm a') || 'NA'}
                        </TableCell>
                        <TableCell align="left">
                          {user.role === 'Admin' ? (
                            <Box>
                              {processingStatus === 'Complete' ? (
                                <>
                                  {/* <RouterLink to={{pathname: `/dashboard/worklist/edit-study/${_id}`}} state={{age : patientAge, gender: patientGender, siteId: site._id}}>
                                      <Tooltip title="update-worklist" arrow><Edit style={iconStyle} /></Tooltip>
                                    </RouterLink>   */}
                                  <RouterLink
                                    to={{ pathname: `/dashboard/worklist/report?studyId=${_id}` }}
                                    state={{ age: patientAge, ethnicity }}
                                  >
                                    <Tooltip title="report" arrow>
                                      <TrendingUp style={iconStyle} />
                                    </Tooltip>
                                  </RouterLink>
                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility
                                      onClick={() => getDicomSignedUrls(patientId, site._id)}
                                      style={iconStyle}
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  {/* <Tooltip title="update-worklist" arrow><Edit style={disableIconStyle} /></Tooltip> */}
                                  <Tooltip title="report" arrow>
                                    <TrendingUp style={disableIconStyle} />
                                  </Tooltip>
                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility onClick={undefined} style={disableIconStyle} />
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          ) : (
                            <Box>
                              {processingStatus === 'Complete' ? (
                                <>
                                  <RouterLink
                                    to={{ pathname: `/dashboard/worklist/report?studyId=${_id}` }}
                                    state={{ age: patientAge, ethnicity }}
                                  >
                                    <Tooltip title="report" arrow>
                                      <TrendingUp style={iconStyle} />
                                    </Tooltip>
                                  </RouterLink>

                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility
                                      onClick={
                                        processingStatus !== 'Failed' &&
                                        (() => getDicomSignedUrls(patientId, site._id))
                                      }
                                      style={
                                        processingStatus === 'Failed' ||
                                        processingStatus === 'Processing'
                                          ? disableIconStyle
                                          : iconStyle
                                      }
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip title="report" arrow>
                                    <TrendingUp style={disableIconStyle} />
                                  </Tooltip>

                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility onClick={undefined} style={disableIconStyle} />
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          )}
                        </TableCell>
                        {/* <TableCell align="right">
                            <WorkListMoreMenu />
                          </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isLoading2 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <IsLoading searchQuery={filterPatientId} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {isStudyNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterPatientId} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Number.isNaN(tableLength) ? 0 : tableLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
    </>
  );
}
