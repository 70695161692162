import { useDispatch, useSelector } from 'react-redux';
import { capitalize, filter } from 'lodash';
import { Icon } from '@iconify/react';
// import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Tooltip,
  Stack,
  Button,
  Snackbar,
  SnackbarContent,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import searchFill from '@iconify/icons-eva/search-fill';
import { Assignment, Edit } from '@material-ui/icons';
import moment from 'moment';

// components
import Page from '../../components/Page';
// import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { WorkListHead } from '../workList/worklist/index';
import { fetchSites } from '../../api/uploadStudy/fetchSites';
import SiteListToolbar from './SiteListToolbar';
import { setUserId } from '../../redux/actions/authActions';

//
// import userLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Site Name', alignRight: false },
  { id: 'shortName', label: 'Short Name', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query.filterSiteName) {
    try {
      return filter(
        array,
        (_user) => _user.name?.toLowerCase().indexOf(query.filterSiteName.toLowerCase()) !== -1
      );
    } catch (error) {
      console.error(error);
    }
  }
  if (query.searchAddress) {
    try {
      return filter(
        array,
        (_user) => _user.address?.toLowerCase().indexOf(query.searchAddress.toLowerCase()) !== -1
      );
    } catch (error) {
      console.error(error);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SiteList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('patientId');
  const [filterSiteName, setFilterSiteName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [USERLIST,setUSERLIST] = useState([]);
  const [sites, setSites] = useState([]);
  // const [deleteSiteId,setDeleteSiteId] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const { user } = useSelector((state) => state.loggedInUser);

  const dispatch = useDispatch();

  const handleSiteGetId = (id) => {
    dispatch(setUserId(id));
  };

  const iconStyle = {
    backgroundColor: '#dcf2d8',
    color: '#4fa52e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'pointer'
  };

  const [responseState, setResponseState] = useState({ error: false, msg: '' });

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { open, vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    async function fecthData() {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        }
        //    else if(res.message === "Request failed with status code 401") {
        //     setResponseState({error:true,msg:res.message});
        //     setState({ open: true, vertical:'top',horizontal:'right'});

        //  }
        else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
    fecthData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sites.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = ( patientId) => {
  //   const selectedIndex = selected.indexOf(patientId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, patientId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterBySiteName = (event) => {
    setFilterSiteName(event.target.value);
  };

  const handleSearchAddress = (event) => {
    setSearchAddress(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sites.length) : 0;

  const filteredSites = applySortFilter(sites, getComparator(order, orderBy), {
    filterSiteName,
    searchAddress
  });

  const isUserNotFound = filteredSites.length === 0;

  let isSearchAddressEmpty = false;

  return (
    <Page title="Sites | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sites
          </Typography>
          {user.role !== 'User' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/sitelist/create-site"
              startIcon={<Icon icon={plusFill} />}
            >
              Create Site
            </Button>
          )}
        </Stack>

        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
        </Snackbar>

        <Card>
          {/* <Box style={{border:'1px solid lightgrey',borderRadius:'10px',width:'16%',margin:'30px 20px',padding:'5px'}}>
            <InputBase style={{padding:'7px'}}
              placeholder="Site Name"
              onChange={event => setSearchedSite(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }}  />
                </InputAdornment>
              }
            />
          </Box> */}

          {user.role !== 'User' && (
            <SiteListToolbar
              numSelected={selected.length}
              filterSiteName={filterSiteName}
              onFilterSiteName={handleFilterBySiteName}
              searchAddress={searchAddress}
              onSearchAddress={handleSearchAddress}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={sites.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredSites
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      if (row === undefined) {
                        isSearchAddressEmpty = true;
                        return row;
                      }
                      return row;
                    })
                    .map((row) => {
                      const { _id: patientId, name, address, createdAt, shortName } = row;
                      const isItemSelected = selected.indexOf(patientId) !== -1;
                      return (
                        <TableRow
                          hover
                          key={patientId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{capitalize(name) || 'NA'}</TableCell>
                          <TableCell align="left">{shortName || 'NA'}</TableCell>
                          <TableCell align="left">{address || 'NA'}</TableCell>
                          <TableCell align="left">
                            {moment(createdAt).format('MMM DD, YYYY, h:mm a') || 'NA'}
                          </TableCell>
                          {user.role !== 'User' && (
                            <TableCell align="left">
                              <RouterLink to="/dashboard/sitelist/site-details">
                                <Tooltip title="Details" arrow>
                                  <Assignment
                                    style={iconStyle}
                                    onClick={() => {
                                      handleSiteGetId(patientId);
                                    }}
                                  />
                                </Tooltip>
                              </RouterLink>
                              <RouterLink
                                to={{
                                  pathname: `/dashboard/sitelist/edit-site/${patientId}`,
                                  state: { siteId: patientId }
                                }}
                              >
                                <Tooltip title="Edit" arrow>
                                  <Edit style={iconStyle} />
                                </Tooltip>
                              </RouterLink>
                              {/* <DeleteSite patientId={patientId} /> */}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {(isUserNotFound || isSearchAddressEmpty) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchAddress} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSites.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
