import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import switchIcon from '@iconify/icons-eva/swap-fill';
// material
import { alpha } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  SnackbarContent,
  Snackbar
} from '@material-ui/core';
// components
import { setLogoutStatus } from '../../redux/actions/authActions';
import { logoutApi } from '../../api/login/logoutApi';
import MenuPopover from '../../components/MenuPopover';
//

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '/dashboard/profile'
  }
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = useState({ isOpen: false, vertical: 'top', horizontal: 'center' });
  const { vertical, horizontal, isOpen } = state;
  // redux state
  const { user, token } = useSelector((state) => state.loggedInUser);

  const displayName = user.username && user.username.toUpperCase();
  const userEmail = user.email;
  const hasBleedSubscription = useSelector((state) => state.loggedInUser.user.sitebleedSubscription);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setState({ ...state, isOpen: false });
    setOpen(false);
  };
  const handleCloseSnackbar = () => {
    setState({ ...state, isOpen: false });
  };
  const handleLogout = async () => {
    const res = await logoutApi(token);
    if (res && res.message === 'Network Error') {
      setResponseState({ error: true, msg: res.message });
      setState({ isOpen: true, vertical: 'top', horizontal: 'right' });
    } else if (res && res.status === 200) {
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('passwordCheck')
      dispatch(setLogoutStatus());
      navigate('/login', { replace: true });
    } else if (res.response) {
      setResponseState({ error: true, msg: res.response.data.msg });
      setState({ isOpen: true, vertical: 'top', horizontal: 'right' });
    }
  };
  const handleSwitchClick = () => {
    setOpen((prev) => !prev);
    window.location = `${process.env.REACT_APP_NEUROFLO_WEB_URL}/login/${JSON.parse(localStorage.loggedInUser).passcode
      }`;
  };

  return (
    <>
      <Snackbar
        severity="red"
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent
          style={{ backgroundColor: 'red' }}
          message={responseState.msg}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Snackbar>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt={displayName} src="photoURL" style={{ backgroundColor: '#16B264' }} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

{hasBleedSubscription ? (
          <MenuItem
            onClick={handleSwitchClick}
            sx={{ typography: 'body2', py: 1, px: 2.5, alignItems: 'flex-start' }}
          >
            <Box
              component={Icon}
              icon={switchIcon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            <Box
              sx={{
                width: '75%',
                whiteSpace: 'break-spaces'
              }}
              component="span"
            >
              Switch to Brain CT Bleed
            </Box>
          </MenuItem>
        ) : null}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
