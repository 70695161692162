import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
// import { showSnackbar } from '../../../components/snackbar/actions';
import { useFormik } from 'formik';
import axios from 'axios';


const formField = {
  password: '',
  confirmPassword: ''
};

export const useChangePassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormvalues] = useState(formField);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });


  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value
    }));
  };
  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/,
      'Password must contain 8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character'
  ),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
  });


  const { token } = useSelector((state) => state.loggedInUser);
  const Token = token.split(' ')[1];
  const formik = useFormik({
    initialValues: {
      token,
      password: '',
      confirmPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      const payload = {
        token:Token,
        password: value.password,
        confirmPassword: value.confirmPassword
      };
      
      axios
      .post(`${process.env.REACT_APP_API_SERVER_URL}/api/users/reset-password`, payload,
      {headers: {
        Authorization: `${token}`
      }
    })
    .then((res) => {
      setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          localStorage.removeItem('passwordCheck')
          if(res.status===500){
            setResponseState({ error: true, msg:res.data.msg });
            setState({ open: true, vertical: 'top', horizontal: 'right' });
  
          }

        })
        .catch((error) => {
          setResponseState({ error: true, msg: error.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        });
    }
  });
    const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };

  return { modalOpen,showConfirmPassword,showPassword,vertical,horizontal,handleClose,open,responseState, handleRequestClose,setModalOpen,handleShowConfirmPassword,handleShowPassword, handleFormUpdate, formValues, formik};
};
