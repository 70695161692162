import { useSelector } from 'react-redux';
import { capitalize, filter } from 'lodash';
import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Table,
  Tooltip,
  Stack,
  Button,
  Snackbar,
  SnackbarContent,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Edit } from '@material-ui/icons';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { WorkListHead } from '../workList/worklist/index';
import { fetchEntities } from '../../api/entityList/fetchEntities';
import EntityListToolbar from './EntityListToolbar';

//
// import userLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: 'id', label: 'Entity ID', alignRight: false },
  { id: 'name', label: 'Entity Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EntityList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('patientId');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [USERLIST,setUSERLIST] = useState([]);
  // const [searchedSite,setSearchedSite] = useState('');
  const [entities, setEntities] = useState([]);
  // const [deleteSiteId,setDeleteSiteId] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const [modalOpen, setModalOpen] = useState(false);
  const { user } = useSelector((state) => state.loggedInUser);

  function getModalStyle() {
    const top = 33;
    const left = 30;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${left}%, ${top}%)`
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  }));

  const Classes = useStyles();

  // const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDeleteEntity = async () => {
    // const res = await axios.delete(`${process.env.REACT_APP_API_SERVER_URL}/api/entities/${id}`,config)
    //             .catch((error)=> error);
  };

  const iconStyle = {
    backgroundColor: '#dcf2d8',
    color: '#4fa52e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'pointer'
  };

  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [filterEntityName, setFilterEntityName] = useState('');
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { open, vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    async function fetchData() {
      const res = await fetchEntities();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setEntities(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
    fetchData();
  }, []);

  const handleFilterByEntityName = (e) => {
    setFilterEntityName(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = entities.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (patientId) => {
  //   const selectedIndex = selected.indexOf(patientId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, patientId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleEditUser = (id) => {
  //   dispatch(setUserId(id));
  // };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - entities.length) : 0;

  const filteredEntities = applySortFilter(
    entities,
    getComparator(order, orderBy),
    filterEntityName
  );

  const isUserNotFound = filteredEntities.length === 0;

  return (
    <Page title="Entities | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Entities
          </Typography>
          {user.role !== 'User' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/entities/create-entity"
              startIcon={<Icon icon={plusFill} />}
            >
              Create Entity
            </Button>
          )}
        </Stack>

        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
        </Snackbar>

        <Card>
          {user.role !== 'User' && (
            <EntityListToolbar
              numSelected={selected.length}
              filterEntityName={filterEntityName}
              onFilterEntityName={handleFilterByEntityName}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={entities.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredEntities
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id: patientId, description, name } = row;
                      const isItemSelected = selected.indexOf(patientId) !== -1;

                      return (
                        <TableRow
                          hover
                          key={patientId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => handleClick(patientId)}
                            />
                          </TableCell> */}
                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={patientId} >{name[0]}</Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {patientId || "NA"}
                              </Typography>
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left">{capitalize(name) || 'NA'}</TableCell>
                          <TableCell align="left">{description || 'NA'}</TableCell>
                          {user.role !== 'User' && (
                            <TableCell align="left">
                              <RouterLink
                                to={{ pathname: `/dashboard/entities/edit-entity/${patientId}` }}
                              >
                                <Tooltip title="Edit" arrow>
                                  <Edit style={iconStyle} />
                                </Tooltip>
                              </RouterLink>
                            </TableCell>
                          )}

                          <Modal open={modalOpen} onClose={handleModalClose}>
                            <div style={modalStyle} className={Classes.paper}>
                              <Box my={3}>
                                <p>Do You want to Delete this site permanently</p>
                              </Box>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleDeleteEntity(patientId);
                                  setModalOpen(false);
                                }}
                                style={{ marginRight: '10px' }}
                              >
                                Delete
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setModalOpen(false);
                                }}
                              >
                                {' '}
                                Cancel{' '}
                              </Button>
                            </div>
                          </Modal>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterEntityName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredEntities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
