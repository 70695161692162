import { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

function ChangePassword({ userId }) {
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const textStyle = {
    margin: '5px 0 5px 0'
  };

  const schemaValidation = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Min 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        '1 special char, 1 UpperCase, 1 number '
      )
      .required('password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: schemaValidation,
    onSubmit: async (values) => {
      const res = axios
        .patch(
          `http://${process.env.REACT_APP_API_SERVER_URL}/api/users/reset-password/${userId}`,
          values
        )
        .catch((err) => console.err(err));

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { getFieldProps, errors, touched } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Box my={1}>
          <h3 style={{ margin: '0 0 10px 5px' }}>Change Password</h3>

          <TextField
            variant="standard"
            style={textStyle}
            name="newPassword"
            fullWidth
            label="new password"
            {...getFieldProps('newPassword')}
            helperText={touched.newPassword && errors.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
          />

          <TextField
            variant="standard"
            style={textStyle}
            name="confirmPassword"
            fullWidth
            label="confirm password"
            {...getFieldProps('confirmPassword')}
            helperText={touched.confirmPassword && errors.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          />

          <div style={{ marginTop: '10px' }}>
            <Button type="submit" fullWidth variant="contained">
              Update
            </Button>
          </div>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default ChangePassword;
