import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export const useRegister = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [counter, setCounter] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationNameParam = queryParams.get('organizationName');

  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const getPhoneValidationSchema = (countryCode) => {
    const phoneRegexMap = {
      93: /^\d{7}$/, // Afghanistan
      355: /^\d{8}$/, // Albania
      213: /^\d{9}$/, // Algeria
      376: /^\d{6}$/, // Andorra
      244: /^\d{9}$/, // Angola
      54: /^\d{10}$/, // Argentin
      '1-268': /^\d{7}$/, // AntiguaandBarbuda
      374: /^\d{8}$/, // Armenia
      297: /^\d{7}$/, // Aruba
      43: /^\d{6,9}$/, // Austria
      994: /^\d{15}$/, // Azerbaijan
      973: /^\d{8}$/, // Bahrain
      '1-242': /^\d{10}$/, // Bahamas
      880: /^\d{10}$/, // Bangladesh
      '1-246': /^\d{7}$/, // Barbados
      375: /^\d{9}$/, // Belarus
      32: /^\d{9,10}$/, // Belgium
      229: /^\d{8}$/, // Benin
      975: /^\d{8}$/, // Bhutan/
      591: /^\d{7,8}$/, // Bolivia
      387: /^\d{8}$/, // BosniaandHerzegovina|| false
      267: /^\d{7}$/, // Botswana
      55: /^\d{8,9}$/, // Brazil
      246: /^\d{6}$/, // BritishIndianOceanTerritory
      673: /^\d{7}$/, // Brunei
      359: /^\d{10}$/, // Bulgaria
      226: /^\d{7}$/, // BurkinaFaso
      257: /^\d{7}$/, // Burundi
      855: /^\d{10}$/, // Cambodia
      237: /^\d{8,9}$/, // Cameroon
      '1-647': /^\d{11}$/, // Canada
      238: /^\d{7}$/, // Cape erde
      599: /^\d{7}$/, // CaribbeanNetherlands
      236: /^\d{8}$/, // CentralAfrica
      235: /^\d{6}$/, // Chad
      56: /^\d{8,9}$/, // Chile
      86: /^\d{11}$/, // China
      57: /^\d{10}$/, // Colombia
      269: /^\d{7}$/, // Comoros
      242: /^\d{9}$/, // Congo
      506: /^\d{8}$/, // CostaRica
      225: /^\d{8}$/, // CotedIvoire(IvoryCoast)
      385: /^\d{9}$/, // Croatia
      53: /^\d{7}$/, // Cuba
      357: /^\d{8}$/, // Cyprus
      420: /^\d{9}$/, // CzechRepublic
      45: /^\d{8}$/, // Denmark
      253: /^\d{6}$/, // Djibouti
      '1-767': /^\d{7}$/, // Dominica
      '1-809': /^\d{7}$/, // DominicanRepublic
      593: /^\d{8,9}$/, // Ecuador
      20: /^\d{7,8}$/, // Egypt
      503: /^\d{7}$/, // ElSalvador
      240: /^\d{8}$/, // EquatorialGuinea
      291: /^\d{7}$/, // Eritrea
      372: /^\+372\d{7}$/, // Estonia
      251: /^\d{9}$/, // Ethiopia
      679: /^\d{7}$/, // Fiji
      358: /^\d{7}$/, // Finland
      33: /^\d{10}$/, // France
      594: /^\d{9}$/, // FrenchGuiana
      689: /^\d{6}$/, // FrenchPolynesia
      241: /^\d{8}$/, // Gabon
      220: /^\d{6}$/, // Gambia
      995: /^\d{8}$/, // Georgia
      49: /^\d{10}$/, // Germany
      233: /^\d{9}$/, // Ghana
      30: /^\d{10}$/, // Greece
      224: /^\d{8}$/, // Guinea
      502: /^\d{8}$/, // Guatemala
      245: /^\d{7}$/, // Guinea-Bissau
      592: /^\d{6,7}$/, // Guyana
      509: /^\d{8}$/, // Haiti
      504: /^\d{8}$/, // Honduras
      852: /^\d{8}$/, // HongKong
      36: /^\d{8}$/, // Hungary
      354: /^\d{7}$/, // Iceland
      91: /^\d{10}$/, // India
      62: /^\d{8,12}$/, // Indonesia
      98: /^\d{10}$/, // Iran
      964: /^\d{6,7}$/, // Iraq
      353: /^\d{9}$/, // Ireland
      972: /^\d{9}$/, // Israel
      39: /^\+39\d{10}$/, // Italy
      7: /^\d{10,11}$/, // Kazakhstan
      254: /^\d{10}$/, // Kenya
      686: /^\d{7}$/, // Kiribati
      383: /^\d{9}$/, // Kosovo
      965: /^\d{8}$/, // Kuwait
      996: /^\d{9}$/, // Kyrgyzstan
      856: /^\d{9}$/, // Laos
      371: /^\+371\d{8}$/, // Latvia
      961: /^\d{7,8}$/, // Lebanon
      266: /^\d{8}$/, // Lesotho
      231: /^\d{7}$/, // Liberia
      218: /^\d{9}$/, // Libya
      423: /^\d{7}$/, // Liechtenstein
      370: /^\d{8}$/, // Lithuania
      352: /^\d{8}$/, // Luxembourg
      853: /^\d{8}$/, // Macau
      389: /^\d{8}$/, // NorthMacedonia
      261: /^\d{8}$/, // Madagascar
      265: /^\d{8}$/, // Malawi
      60: /^\d{10}$/, // Malaysia
      960: /^\d{15}$/, // Maldives
      223: /^\d{8}$/, // Mali
      692: /^\d{7}$/, // MarshallIslands
      596: /^\d{9}$/, // Martinique
      230: /^\d{7}$/, // Mauritius
      52: /^\d{10}$/, // Mexico
      691: /^\d{7}$/, // Micronesia
      373: /^\d{8}$/, // Moldova
      377: /^\d{8}$/, // Monaco
      976: /^\d{8}$/, // Mongolia
      382: /^\d{9}$/, // Montenegro
      212: /^\d{8}$/, // Morocco
      258: /^\d{9}$/, // Mozambique
      95: /^\d{8}$/, // Myanmar
      264: /^\d{8}$/, // Namibia
      674: /^\d{7}$/, // Nauru
      977: /^\d{10}$/, // Nepal
      31: /^\d{9}$/, // Netherlands
      687: /^\d{6}$/, // NewCaledonia
      64: /^\d{10}$/, // NewZealand
      505: /^\d{8}$/, // Nicaragua
      227: /^\d{8}$/, // Niger
      850: /^\d{8}$/, // NorthKorea
      47: /^\d{8}$/, // Norway
      968: /^\d{8}$/, // Oman
      92: /^\d{10}$/, // Pakistan
      680: /^\d{7}$/, // Palau
      970: /^\d{8}$/, // Palestine
      507: /^\d{8}$/, // Panama
      675: /^\d{7}$/, // PapuaNewGuinea
      595: /^\d{9}$/, // Paraguay
      51: /^\d{9}$/, // Peru
      63: /^\d{10}$/, // Philippines
      48: /^\d{9}$/, // Poland
      351: /^\d{9}$/, // Portugal
      '1-787': /^\d{7}$/, // PuertoRico
      974: /^\d{7}$/, // Qatar
      262: /^\d{9}$/, // Reunion
      40: /^\d{9}$/, // Romania
      '1-869': /^\d{7}$/, // SaintKittsandNevis
      '1-758': /^\d{7}$/, // SaintLucia
      '1-784': /^\d{7}$/, // SaintVincenandtheGrenadines
      685: /^\d{5}$/, // Samoa
      378: /^\d{6}$/, // Sanmarino
      966: /^\d{9}$/, // SaudiArabia
      221: /^\d{9}$/, // Senegal
      381: /^\d{8}$/, // Serbia
      248: /^\d{7}$/, // Seychelles
      232: /^\d{7}$/, // SierraLeone
      65: /^\d{8}$/, // Singapore
      421: /^\d{9}$/, // Slovakia
      386: /^\d{8}$/, // Slovenia
      677: /^\d{7}$/, // Solomonslands
      27: /^\d{9}$/, // SouthAfrica
      82: /^\d{9,11}$/, // South/Korea
      211: /^\d{9}$/, // SouthSudan
      34: /^\d{9}$/, // Spain
      94: /^\d{10}$/, // SriLanka
      249: /^\d{9}$/, // Sudan
      597: /^\d{7}$/, // Suriname
      268: /^\d{7}$/, // Eswatini
      46: /^\d{9,10}$/, // Sweden
      41: /^\d{9,10}$/, // Switzerland
      963: /^\d{9}$/, // Syria
      886: /^\d{9}$/, // Taiwan
      992: /^\d{9}$/, // Tajikistan
      255: /^\d{9}$/, // Tanzania
      66: /^\d{10}$/, // Thailand
      670: /^\d{6}$/, // Timor-Leste
      228: /^\d{8}$/, // Togo
      676: /^\d{7}$/, // Tonga
      '1-868': /^\d{7}$/, // TrinidadandTobago
      216: /^\d{8}$/, // Tunisia
      90: /^\d{9}$/, // Turkey
      993: /^\d{8}$/, // Turkmenistan
      688: /^\d{5}$/, // Tuvalu
      256: /^\d{9}$/, // Uganda
      380: /^\d{9}$/, // Ukraine
      971: /^\d{9}$/, // UnitedArabEmirates
      598: /^\d{8}$/, // Uruguay
      998: /^\d{9}$/, // Uzbekistan
      678: /^\d{5}$/, // Vanuatu
      379: /^\d{8}$/, // VaticanCity
      58: /^\d{10}$/, // Venezuela
      84: /^\d{9}$/, // Vietnam
      967: /^\d{9}$/, // Yemen
      260: /^\d{9}$/, // Zambia
      263: /^\d{9}$/ // Zimbabwe
    };
    const phoneRegex = phoneRegexMap[countryCode];

    return Yup.string()
      .matches(phoneRegex, 'Invalid phone number for the selected country code')
      .required('Phone Number Required');
  };

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    username: Yup.string().required('Username is required'),
  //   password: Yup.string().required('Password is required').matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/,
  //     'Password must contain 8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character'
  // ),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    entityName: Yup.string().required('Organization name is required'),
    // siteName: Yup.string().required('Site name is required'),
    // siteAddress: Yup.string().required('Site address is required'),
    // siteShortName: Yup.string().required('Site short name is required'),
    // sitebleedSubscription: Yup.boolean().required('Site bleed subscription is required'),
    phone: Yup.string().min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number must be at most 15 digits')
    .matches(/^\d+$/, 'Phone number can only contain digits'),
    // countryCode: Yup.string()
    //   .matches(/^\d{1,4}$/, 'Country code must be 1 to 4 digits')
    //   .required('Country Code Required'),
  });

  const { vertical, horizontal, open } = state;

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      email: '',
      phone: '',
      confirmPassword: '',
      entityName: organizationNameParam || '',
      sitebleedSubscription: false,
      countryCode: '1',
      isWhatsAppOptIn: false
    },
    validationSchema: RegistrationSchema,
    onSubmit: async (value) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/register`, {
            // const res = await fetch(`http://localhost:3030/api/auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firstname: value.firstname,
            lastname: value.lastname,
            username: value.username,
            // password: value.password,
            // confirmPassword: value.confirmPassword,
            email: value.email,
            phone: value.phone,
            entityName: value.entityName,
            // siteName:value.siteName,
            sitebleedSubscription: value.sitebleedSubscription,
            countryCode: value.countryCode,
            isWhatsAppOptIn: value.isWhatsAppOptIn
          })
        });
        const resJson = await res.json();
        console.log(resJson);
        if (resJson.msg==="User Registered successfully") {
          setResponseState({error: false,msg: 'Registered Successfully! Email is sent to you shortly'});
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          // navigate('/login');
        } else {
          setResponseState({ error: true, msg: resJson.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        setResponseState({ error: true, msg: e.response.msg });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
      }
    }
  });
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return {
    formik,
    responseState,
    vertical,
    horizontal,
    open,
    handleClose
  };
};
