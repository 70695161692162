import React, { useState, useEffect } from 'react';
import {
  Breadcrumbs,
  Snackbar,
  SnackbarContent,
  Button,
  Container,
  CircularProgress
} from '@material-ui/core';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import WorklistRepNVA from './worklistReport/WorklistRepNVA';
import WorklistRepBVA from './worklistReport/WorklistRepBVA';

const WorklistReport = () => {
  // const classes = useStyles();

  const spanStyle = {
    paddingLeft: '12px'
  };

  const [editReportForm, setEditReportForm] = useState({
    PatientID: '',
    PatientCondition: '',
    SiteName: '',
    PatientName: '',
    PatientAge: '',
    PatientGender: '',
    PatientContact: '',
    RefPhysician: '',
    ExamDate: '',
    naData: {
      na11: '',
      na12: '',
      na13: '',
      na14: '',
      na15: ''
    },

    bva1: '',
    bva2: '',
    bva3: '',
    bva4: '',
    bva5: '',
    bva6: '',
    bva7: '',
    bva8: '',

    bva21: '',
    bva22: '',
    bva221: '',
    bva225: '',
    bva226: '',
    bva233: '',
    bva234: '',
    bva235: '',

    bva32: '',
    bva321: '',
    bva325: '',
    bva326: '',
    bva333: '',
    bva334: '',
    bva335: '',

    vdaData: {
      vda1: { output: 'NIL', reference: 'NA' },
      vda2: { output: 'NIL', reference: 'NA' },
      vda3: { output: 'NIL', reference: 'NA' }
    }
  });
  const [render, setRender] = useState(false);
  const [checkedForRef] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editReport] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const { user } = useSelector((state) => state.loggedInUser);
  const [radar, setRadar] = useState({});
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  let showRef = true;

  const handleDownload = () => {
    const method = 'GET';
    setLoading(true);
    const url = `${process.env.REACT_APP_API_SERVER_URL}/api/reports/download/${studyId}`;
    axios
      .request({
        url,
        method,
        params: {
          check: showRef,
          user: user.role
        },
        responseType: 'blob'
      })
      .then(({ data }) => {
        setLoading(false);
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${editReportForm.PatientID}_neuroshield_report.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const search = useLocation();
  // const {ethnicity, age} = search?.state
  const ethnicity = search?.state?.ethnicity;
  const age = search?.state?.age;
  const studyId = new URLSearchParams(search.search).get('studyId');

  if (ethnicity === 'Indian' && (age < 11 || age > 80)) {
    showRef = false;
  } else if (ethnicity === 'Caucasian' && (age < 50 || age > 80)) {
    showRef = false;
  } else {
    showRef = true;
  }

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/reports/${studyId}`).then((response) => {
      const { data } = response;
      if (data.data) {
        const temp = data.data;
        setRadar(data.data.radar);

        setChecked(temp.report.isRefRangeChecked);
        // const study = data.data.study;
        const tempObj = editReportForm;
        tempObj.PatientID = temp.study.dicomPatientId;
        tempObj.PatientName = temp.study.patientName;
        tempObj.PatientAge = temp.report.age;
        tempObj.RefPhysician = temp.report.refPhysician;
        tempObj.SiteName = temp.site.name;
        tempObj.SiteID = temp.site._id;
        tempObj.StudyPatientID = temp.study.patientId;
        tempObj.studyId = temp.study.studyId;
        tempObj.PatientGender = temp.study.patientGender;
        tempObj.ExamDate = moment.utc(temp.study.uploadedDate).format('MMM DD, YYYY, h:mm a');
        tempObj.isRefRangeChecked = temp.report.isRefRangeChecked;
        tempObj.bva1 = temp.report.bva11;
        tempObj.bva2 = temp.report.bva12;
        tempObj.bva3 = temp.report.bva121;
        tempObj.bva4 = temp.report.bva125;
        tempObj.bva5 = temp.report.bva126;
        tempObj.bva6 = temp.report.bva133;
        tempObj.bva7 = temp.report.bva134;
        tempObj.bva8 = temp.report.bva135;
        tempObj.bva9 = temp.report.bva136;
        tempObj.bva10 = temp.report.bva137;
        tempObj.bva11 = temp.report.bva138;
        tempObj.bva12 = temp.report.bva139;
        tempObj.bva13 = temp.report.bva140;
        tempObj.bva14 = temp.report.bva141;
        tempObj.bva15 = temp.report.bva142;
        tempObj.bva16 = temp.report.bva143;
        tempObj.bva17 = temp.report.bva144;
        tempObj.bva18 = temp.report.bva145;
        tempObj.bva19 = temp.report.bva146;
        tempObj.bva20 = temp.report.bva147;
        tempObj.bva148 = temp.report.bva148;
        tempObj.bva149 = temp.report.bva149;
        tempObj.bva23 = temp.report.bva150;
        tempObj.bva24 = temp.report.bva151;
        tempObj.bva25 = temp.report.bva152;
        tempObj.bva26 = temp.report.bva153;
        tempObj.bva27 = temp.report.bva154;
        tempObj.bva28 = temp.report.bva155;
        tempObj.bva29 = temp.report.bva156;
        tempObj.bva30 = temp.report.bva157;
        tempObj.bva31 = temp.report.bva158;
        tempObj.bva132 = temp.report.bva159;

        tempObj.bva21 = temp.report.bva21;
        tempObj.bva22 = temp.report.bva22;
        tempObj.bva221 = temp.report.bva221;
        tempObj.bva225 = temp.report.bva225;
        tempObj.bva226 = temp.report.bva226;
        tempObj.bva233 = temp.report.bva233;
        tempObj.bva234 = temp.report.bva234;
        tempObj.bva235 = temp.report.bva235;
        tempObj.bva236 = temp.report.bva236;
        tempObj.bva237 = temp.report.bva237;
        tempObj.bva238 = temp.report.bva238;
        tempObj.bva239 = temp.report.bva239;
        tempObj.bva240 = temp.report.bva240;
        tempObj.bva241 = temp.report.bva241;
        tempObj.bva242 = temp.report.bva242;
        tempObj.bva243 = temp.report.bva243;
        tempObj.bva244 = temp.report.bva244;
        tempObj.bva245 = temp.report.bva245;
        tempObj.bva246 = temp.report.bva246;
        tempObj.bva247 = temp.report.bva247;
        tempObj.bva248 = temp.report.bva248;
        tempObj.bva249 = temp.report.bva249;
        tempObj.bva250 = temp.report.bva250;
        tempObj.bva251 = temp.report.bva251;
        tempObj.bva252 = temp.report.bva252;
        tempObj.bva253 = temp.report.bva253;
        tempObj.bva254 = temp.report.bva254;
        tempObj.bva255 = temp.report.bva255;
        tempObj.bva256 = temp.report.bva256;
        tempObj.bva257 = temp.report.bva257;
        tempObj.bva258 = temp.report.bva258;
        tempObj.bva259 = temp.report.bva259;

        tempObj.bva32 = temp.report.bva32;
        tempObj.bva321 = temp.report.bva321;
        tempObj.bva325 = temp.report.bva325;
        tempObj.bva326 = temp.report.bva326;
        tempObj.bva333 = temp.report.bva333;
        tempObj.bva334 = temp.report.bva334;
        tempObj.bva335 = temp.report.bva335;

        tempObj.bva336 = temp.report.bva336;
        tempObj.bva337 = temp.report.bva337;
        tempObj.bva338 = temp.report.bva338;
        tempObj.bva339 = temp.report.bva339;
        tempObj.bva340 = temp.report.bva340;
        tempObj.bva341 = temp.report.bva341;
        tempObj.bva342 = temp.report.bva342;
        tempObj.bva343 = temp.report.bva343;
        tempObj.bva344 = temp.report.bva344;
        tempObj.bva345 = temp.report.bva345;
        tempObj.bva346 = temp.report.bva346;
        tempObj.bva347 = temp.report.bva347;
        tempObj.bva348 = temp.report.bva348;
        tempObj.bva349 = temp.report.bva349;
        tempObj.bva350 = temp.report.bva350;
        tempObj.bva351 = temp.report.bva351;
        tempObj.bva352 = temp.report.bva352;
        tempObj.bva353 = temp.report.bva353;
        tempObj.bva354 = temp.report.bva354;
        tempObj.bva355 = temp.report.bva355;
        tempObj.bva356 = temp.report.bva356;
        tempObj.bva357 = temp.report.bva357;
        tempObj.bva358 = temp.report.bva358;
        tempObj.bva359 = temp.report.bva359;

        tempObj.colorbva11 = temp.report.colorbva11;
        tempObj.colorbva12 = temp.report.colorbva12;
        tempObj.colorbva121 = temp.report.colorbva121;
        tempObj.colorbva125 = temp.report.colorbva125;
        tempObj.colorbva126 = temp.report.colorbva126;
        tempObj.colorbva133 = temp.report.colorbva133;
        tempObj.colorbva134 = temp.report.colorbva134;
        tempObj.colorbva135 = temp.report.colorbva135;
        tempObj.colorbva136 = temp.report.colorbva136;
        tempObj.colorbva137 = temp.report.colorbva137;
        tempObj.colorbva138 = temp.report.colorbva138;
        tempObj.colorbva139 = temp.report.colorbva139;
        tempObj.colorbva140 = temp.report.colorbva140;
        tempObj.colorbva141 = temp.report.colorbva141;
        tempObj.colorbva142 = temp.report.colorbva142;
        tempObj.colorbva143 = temp.report.colorbva143;
        tempObj.colorbva144 = temp.report.colorbva144;
        tempObj.colorbva145 = temp.report.colorbva145;
        tempObj.colorbva146 = temp.report.colorbva146;
        tempObj.colorbva147 = temp.report.colorbva147;
        tempObj.colorbva148 = temp.report.colorbva148;
        tempObj.colorbva149 = temp.report.colorbva149;
        tempObj.colorbva150 = temp.report.colorbva150;
        tempObj.colorbva151 = temp.report.colorbva151;
        tempObj.colorbva152 = temp.report.colorbva152;
        tempObj.colorbva153 = temp.report.colorbva153;
        tempObj.colorbva154 = temp.report.colorbva154;
        tempObj.colorbva155 = temp.report.colorbva155;
        tempObj.colorbva156 = temp.report.colorbva156;
        tempObj.colorbva157 = temp.report.colorbva157;
        tempObj.colorbva158 = temp.report.colorbva158;
        tempObj.colorbva159 = temp.report.colorbva159;

        tempObj.vda11 = temp.report.vda11;
        tempObj.vda12 = temp.report.vda12;
        tempObj.vda21 = temp.report.vda21;
        tempObj.vda22 = temp.report.vda22;
        tempObj.colorvda1 = temp.report.colorvda1;

        tempObj.isRefRangeChecked = temp.report.isRefRangeChecked;
        // tempObj.colorvda2 = temp.report.vda2;
        tempObj.additionalComment = temp.report.additionalComment;
        tempObj.output = temp.report.output;
        tempObj.mrpi = temp.report.mrpi;
        setEditReportForm(tempObj);
        setRender(true);
      }
    });
  }, []);

  useEffect(() => {}, [render]);

  const formik = useFormik({
    initialValues: {
      PatientID: editReportForm.PatientID,
      StudyPatientID: editReportForm.StudyPatientID,
      PatientCondition: editReportForm.PatientCondition,
      SiteName: editReportForm.SiteName,
      SiteID: editReportForm.SiteID,
      studyId: editReportForm.studyId,
      PatientName: editReportForm.PatientName,
      PatientAge: editReportForm.PatientAge,
      PatientGender: editReportForm.PatientGender,
      PatientContact: editReportForm.PatientContact,
      RefPhysician: editReportForm.RefPhysician,
      ExamDate: editReportForm.ExamDate,
      isRefRangeChecked: checkedForRef,
      na11: editReportForm.naData.na11,
      na12: editReportForm.naData.na12,
      na13: editReportForm.naData.na13,
      na14: editReportForm.naData.na14,
      na15: editReportForm.naData.na15,

      bva11: editReportForm.bva1,
      bva12: editReportForm.bva2,
      bva121: editReportForm.bva3,
      bva125: editReportForm.bva4,
      bva126: editReportForm.bva5,
      bva133: editReportForm.bva6,
      bva134: editReportForm.bva7,
      bva135: editReportForm.bva8,
      bva136: editReportForm.bva9,
      bva137: editReportForm.bva10,
      bva138: editReportForm.bva11,
      bva139: editReportForm.bva12,
      bva140: editReportForm.bva13,
      bva141: editReportForm.bva14,
      bva142: editReportForm.bva15,
      bva143: editReportForm.bva16,
      bva144: editReportForm.bva17,
      bva145: editReportForm.bva18,
      bva146: editReportForm.bva19,
      bva147: editReportForm.bva20,
      bva148: editReportForm.bva148,
      bva149: editReportForm.bva149,
      bva150: editReportForm.bva23,
      bva151: editReportForm.bva24,
      bva152: editReportForm.bva25,
      bva153: editReportForm.bva26,
      bva154: editReportForm.bva27,
      bva155: editReportForm.bva28,
      bva156: editReportForm.bva29,
      bva157: editReportForm.bva30,
      bva158: editReportForm.bva31,
      bva159: editReportForm.bva132,

      bva21: editReportForm.bva21,
      bva22: editReportForm.bva22,
      bva221: editReportForm.bva221,
      bva225: editReportForm.bva225,
      bva226: editReportForm.bva226,
      bva233: editReportForm.bva233,
      bva234: editReportForm.bva234,
      bva235: editReportForm.bva235,
      bva236: editReportForm.bva236,
      bva237: editReportForm.bva237,
      bva238: editReportForm.bva238,
      bva239: editReportForm.bva239,
      bva240: editReportForm.bva240,
      bva241: editReportForm.bva241,
      bva242: editReportForm.bva242,
      bva243: editReportForm.bva243,
      bva244: editReportForm.bva244,
      bva245: editReportForm.bva245,
      bva246: editReportForm.bva246,
      bva247: editReportForm.bva247,
      bva248: editReportForm.bva248,
      bva249: editReportForm.bva249,
      bva250: editReportForm.bva250,
      bva251: editReportForm.bva251,
      bva252: editReportForm.bva252,
      bva253: editReportForm.bva253,
      bva254: editReportForm.bva254,
      bva255: editReportForm.bva255,
      bva256: editReportForm.bva256,
      bva257: editReportForm.bva257,
      bva258: editReportForm.bva258,
      bva259: editReportForm.bva259,

      bva32: editReportForm.bva32,
      bva321: editReportForm.bva321,
      bva325: editReportForm.bva325,
      bva326: editReportForm.bva326,
      bva333: editReportForm.bva333,
      bva334: editReportForm.bva334,
      bva335: editReportForm.bva335,
      bva336: editReportForm.bva336,
      bva337: editReportForm.bva337,
      bva338: editReportForm.bva338,
      bva339: editReportForm.bva339,
      bva340: editReportForm.bva340,
      bva341: editReportForm.bva341,
      bva342: editReportForm.bva342,
      bva343: editReportForm.bva343,
      bva344: editReportForm.bva344,
      bva345: editReportForm.bva345,
      bva346: editReportForm.bva346,
      bva347: editReportForm.bva347,
      bva348: editReportForm.bva348,
      bva349: editReportForm.bva349,
      bva350: editReportForm.bva350,
      bva351: editReportForm.bva351,
      bva352: editReportForm.bva352,
      bva353: editReportForm.bva353,
      bva354: editReportForm.bva354,
      bva355: editReportForm.bva355,
      bva356: editReportForm.bva356,
      bva357: editReportForm.bva357,
      bva358: editReportForm.bva358,
      bva359: editReportForm.bva359,

      colorbva11: editReportForm.colorbva11,
      colorbva12: editReportForm.colorbva12,
      colorbva121: editReportForm.colorbva121,
      colorbva125: editReportForm.colorbva125,
      colorbva126: editReportForm.colorbva126,
      colorbva133: editReportForm.colorbva133,
      colorbva134: editReportForm.colorbva134,
      colorbva135: editReportForm.colorbva135,
      colorbva136: editReportForm.colorbva136,
      colorbva137: editReportForm.colorbva137,
      colorbva138: editReportForm.colorbva138,
      colorbva139: editReportForm.colorbva139,
      colorbva140: editReportForm.colorbva140,
      colorbva141: editReportForm.colorbva141,
      colorbva142: editReportForm.colorbva142,
      colorbva143: editReportForm.colorbva143,
      colorbva144: editReportForm.colorbva144,
      colorbva145: editReportForm.colorbva145,
      colorbva146: editReportForm.colorbva146,
      colorbva147: editReportForm.colorbva147,
      colorbva148: editReportForm.colorbva148,
      colorbva149: editReportForm.colorbva149,
      colorbva150: editReportForm.colorbva150,
      colorbva151: editReportForm.colorbva151,
      colorbva152: editReportForm.colorbva152,
      colorbva153: editReportForm.colorbva153,
      colorbva154: editReportForm.colorbva154,
      colorbva155: editReportForm.colorbva155,
      colorbva156: editReportForm.colorbva156,
      colorbva157: editReportForm.colorbva157,
      colorbva158: editReportForm.colorbva158,
      colorbva159: editReportForm.colorbva159,

      vda11: editReportForm.vda11,
      vda12: editReportForm.vda12,
      vda21: editReportForm.vda21,
      vda22: editReportForm.vda22,
      colorvda1: editReportForm.colorvda1,
      additionalComment: editReportForm.additionalComment,
      output: editReportForm.output,
      mrpi: editReportForm.mrpi
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .patch(`${process.env.REACT_APP_API_SERVER_URL}/api/reports/${studyId}`, values)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.data.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          // setGetFieldProps(res.data.data.updateBody)
          window.location.reload();
          resetForm({
            values: {
              PatientID: res.data.data.updatedReportBody.PatientID,
              PatientCondition: res.data.data.updatedReportBody.PatientCondition,
              SiteName: res.data.data.updatedReportBody.SiteName,
              PatientName: res.data.data.updatedReportBody.PatientName,
              PatientAge: res.data.data.updatedReportBody.PatientAge,
              PatientGender: res.data.data.updatedReportBody.PatientGender,
              PatientContact: res.data.data.updatedReportBody.PatientContact,
              RefPhysician: res.data.data.updatedReportBody.RefPhysician,
              ExamDate: res.data.data.updatedReportBody.ExamDate,
              na11: res.data.data.updatedReportBody.naData.na11,
              na12: res.data.data.updatedReportBody.naData.na12,
              na13: res.data.data.updatedReportBody.naData.na13,
              na14: res.data.data.updatedReportBody.naData.na14,
              na15: res.data.data.updatedReportBody.naData.na15,

              bva11: res.data.data.updatedReportBody.bva1,
              bva12: res.data.data.updatedReportBody.bva2,
              bva121: res.data.data.updatedReportBody.bva3,
              bva125: res.data.data.updatedReportBody.bva4,
              bva126: res.data.data.updatedReportBody.bva5,
              bva133: res.data.data.updatedReportBody.bva6,
              bva134: res.data.data.updatedReportBody.bva7,
              bva135: res.data.data.updatedReportBody.bva8,
              bva136: res.data.data.updatedReportBody.bva9,
              bva137: res.data.data.updatedReportBody.bva10,
              bva138: res.data.data.updatedReportBody.bva11,
              bva139: res.data.data.updatedReportBody.bva12,
              bva140: res.data.data.updatedReportBody.bva13,
              bva141: res.data.data.updatedReportBody.bva14,
              bva142: res.data.data.updatedReportBody.bva15,
              bva143: res.data.data.updatedReportBody.bva16,
              bva144: res.data.data.updatedReportBody.bva17,
              bva145: res.data.data.updatedReportBody.bva18,
              bva146: res.data.data.updatedReportBody.bva19,
              bva147: res.data.data.updatedReportBody.bva20,
              bva148: res.data.data.updatedReportBody.bva148,
              bva149: res.data.data.updatedReportBody.bva149,
              bva150: res.data.data.updatedReportBody.bva23,
              bva151: res.data.data.updatedReportBody.bva24,
              bva152: res.data.data.updatedReportBody.bva25,
              bva153: res.data.data.updatedReportBody.bva26,
              bva154: res.data.data.updatedReportBody.bva27,
              bva155: res.data.data.updatedReportBody.bva28,
              bva156: res.data.data.updatedReportBody.bva29,
              bva157: res.data.data.updatedReportBody.bva30,
              bva158: res.data.data.updatedReportBody.bva31,
              bva159: res.data.data.updatedReportBody.bva132,

              bva21: res.data.data.updatedReportBody.bva21,
              bva22: res.data.data.updatedReportBody.bva22,
              bva221: res.data.data.updatedReportBody.bva221,
              bva225: res.data.data.updatedReportBody.bva225,
              bva226: res.data.data.updatedReportBody.bva226,
              bva233: res.data.data.updatedReportBody.bva233,
              bva234: res.data.data.updatedReportBody.bva234,
              bva235: res.data.data.updatedReportBody.bva235,
              bva236: res.data.data.updatedReportBody.bva236,
              bva237: res.data.data.updatedReportBody.bva237,
              bva238: res.data.data.updatedReportBody.bva238,
              bva239: res.data.data.updatedReportBody.bva239,
              bva240: res.data.data.updatedReportBody.bva240,
              bva241: res.data.data.updatedReportBody.bva241,
              bva242: res.data.data.updatedReportBody.bva242,
              bva243: res.data.data.updatedReportBody.bva243,
              bva244: res.data.data.updatedReportBody.bva244,
              bva245: res.data.data.updatedReportBody.bva245,
              bva246: res.data.data.updatedReportBody.bva246,
              bva247: res.data.data.updatedReportBody.bva247,
              bva248: res.data.data.updatedReportBody.bva248,
              bva249: res.data.data.updatedReportBody.bva249,
              bva250: res.data.data.updatedReportBody.bva250,
              bva251: res.data.data.updatedReportBody.bva251,
              bva252: res.data.data.updatedReportBody.bva252,
              bva253: res.data.data.updatedReportBody.bva253,
              bva254: res.data.data.updatedReportBody.bva254,
              bva255: res.data.data.updatedReportBody.bva255,
              bva256: res.data.data.updatedReportBody.bva256,
              bva257: res.data.data.updatedReportBody.bva257,
              bva258: res.data.data.updatedReportBody.bva258,
              bva259: res.data.data.updatedReportBody.bva259,

              bva32: res.data.data.updatedReportBody.bva32,
              bva321: res.data.data.updatedReportBody.bva321,
              bva325: res.data.data.updatedReportBody.bva325,
              bva326: res.data.data.updatedReportBody.bva326,
              bva333: res.data.data.updatedReportBody.bva333,
              bva334: res.data.data.updatedReportBody.bva334,
              bva335: res.data.data.updatedReportBody.bva335,
              bva336: res.data.data.updatedReportBody.bva336,
              bva337: res.data.data.updatedReportBody.bva337,
              bva338: res.data.data.updatedReportBody.bva338,
              bva339: res.data.data.updatedReportBody.bva339,
              bva340: res.data.data.updatedReportBody.bva340,
              bva341: res.data.data.updatedReportBody.bva341,
              bva342: res.data.data.updatedReportBody.bva342,
              bva343: res.data.data.updatedReportBody.bva343,
              bva344: res.data.data.updatedReportBody.bva344,
              bva345: res.data.data.updatedReportBody.bva345,
              bva346: res.data.data.updatedReportBody.bva346,
              bva347: res.data.data.updatedReportBody.bva347,
              bva348: res.data.data.updatedReportBody.bva348,
              bva349: res.data.data.updatedReportBody.bva349,
              bva350: res.data.data.updatedReportBody.bva350,
              bva351: res.data.data.updatedReportBody.bva351,
              bva352: res.data.data.updatedReportBody.bva352,
              bva353: res.data.data.updatedReportBody.bva353,
              bva354: res.data.data.updatedReportBody.bva354,
              bva355: res.data.data.updatedReportBody.bva355,
              bva356: res.data.data.updatedReportBody.bva356,
              bva357: res.data.data.updatedReportBody.bva357,
              bva358: res.data.data.updatedReportBody.bva358,
              bva359: res.data.data.updatedReportBody.bva359,

              colorbva11: editReportForm.colorbva11,
              colorbva12: editReportForm.colorbva12,
              colorbva121: editReportForm.colorbva121,
              colorbva125: editReportForm.colorbva125,
              colorbva126: editReportForm.colorbva126,
              colorbva133: editReportForm.colorbva133,
              colorbva134: editReportForm.colorbva134,
              colorbva135: editReportForm.colorbva135,
              colorbva136: editReportForm.colorbva136,
              colorbva137: editReportForm.colorbva137,
              colorbva138: editReportForm.colorbva138,
              colorbva139: editReportForm.colorbva139,
              colorbva140: editReportForm.colorbva140,
              colorbva141: editReportForm.colorbva141,
              colorbva142: editReportForm.colorbva142,
              colorbva143: editReportForm.colorbva143,
              colorbva144: editReportForm.colorbva144,
              colorbva145: editReportForm.colorbva145,
              colorbva146: editReportForm.colorbva146,
              colorbva147: editReportForm.colorbva147,
              colorbva148: editReportForm.colorbva148,
              colorbva149: editReportForm.colorbva149,
              colorbva150: editReportForm.colorbva150,
              colorbva151: editReportForm.colorbva151,
              colorbva152: editReportForm.colorbva152,
              colorbva153: editReportForm.colorbva153,
              colorbva154: editReportForm.colorbva154,
              colorbva155: editReportForm.colorbva155,
              colorbva156: editReportForm.colorbva156,
              colorbva157: editReportForm.colorbva157,
              colorbva158: editReportForm.colorbva158,
              colorbva159: editReportForm.colorbva159,

              vda11: res.data.data.updatedReportBody.vda11,
              vda12: res.data.data.updatedReportBody.vda12,
              vda21: res.data.data.updatedReportBody.vda21,
              vda22: res.data.data.updatedReportBody.vda22,
              // colorvda1: temp.res.data.data.report.vda1;
              additionalComment: res.data.data.updatedReportBody.additionalComment
            }
          });
          // setEditReportForm(res?.data?.data?.updatedReportBody);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });
  const { getFieldProps } = formik;
  return (
    <Container>
      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <Snackbar
            severity={responseState.error ? 'red' : 'green'}
            autoHideDuration={5000}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <SnackbarContent
              message={responseState.msg}
              style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            />
          </Snackbar>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Breadcrumbs aria-label="breadcrumb" my={3}>
                <Link color="inherit" to="/dashboard/worklist">
                  Worklist
                </Link>
                <Link color="textPrimary" to="/dashboard/worklist/report" aria-current="page">
                  Report
                </Link>
              </Breadcrumbs>
            </div>
            <div>
              {/* { user.role === 'Admin' &&
              (!editReport ? (
                <Button
                  onClick={() => setEditReport(true)}
                  startIcon={<Icon icon={editFill} sx={iconStyle} />}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setEditReport(false);
                    handleSubmit();
                  }}
                  startIcon={<Icon icon={saveFill} sx={iconStyle} />}
                >
                  Save
                </Button>
              ))} */}
              {loading ? (
                <Button
                  style={{ margin: '10px 5px 0px 5px' }}
                  variant="contained"
                  color="primary"
                  disabled
                >
                  <CircularProgress color="secondary" size={25} />
                  <span style={spanStyle}>Downloading</span>
                </Button>
              ) : (
                <Button
                  style={{ margin: '10px 5px 0px 5px' }}
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                  startIcon={<Icon icon={plusFill} />}
                >
                  Download Report
                </Button>
              )}
            </div>
          </div>
          <div id="neuro-shield-report">
            <WorklistRepNVA getFieldProps={getFieldProps} edit={editReport} />
            {/* <WorklistRepNA getFieldProps={getFieldProps} edit={editReport} /> */}
            <WorklistRepBVA
              ethnicity={ethnicity}
              age={age}
              getFieldProps={getFieldProps}
              edit={editReport}
              handleCheckChange={handleCheckChange}
              checked={checked}
            />
            {/* <br />
            <br />
            <br />
            {showRef && (
                <table>
                  <td><h3>Macro Data</h3></td>
                  <td><h3>Micro Data</h3></td>
                  <tr>
                      <td><img src={radar.macro_radar} alt="Macro Radar" height="400" width="450"/></td>
                      <td><img src={radar.micro_radar} alt="Micro Radar" height="400" width="500"/></td>
                  </tr>
                </table> 
            )} */}
            {/* <HowToRead /> */}
          </div>
        </Form>
      </FormikProvider>
    </Container>
  );
};
export default WorklistReport;
