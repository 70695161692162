import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import greentick from '../../../assests/Images/download.png'

const ConfirmEmail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const confirmToken = searchParams.get('confirmToken');
  console.log(confirmToken, 'tokenConfirm');
  const [confirmationStatus, setConfirmationStatus] = useState('pending');
  const navigate = useNavigate();

  const confirmEmail = async (confirmToken) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/confirmEmail`, {
        // const res = await fetch(`http://localhost:3030/api/auth/confirmEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          // eslint-disable-next-line object-shorthand
          confirmToken: searchParams.get('confirmToken')
        })
      });

      const resJson = await res.json();

      // Show success message if confirmation is successful
      if (resJson.success) {
        setConfirmationStatus('success')
        alert('Email confirmed successfully! You can now log in.');
        navigate('/login');
      }
      if (resJson.errorType){
        console.log(resJson.status)
        alert('Email is alredy confirmed. You can Login')
        navigate('/login')
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (confirmToken) {
      const payload = {
        confirmToken:searchParams.get('confirmToken')
      };                                                                                                                                                                       
      confirmEmail(payload);
    }
  }, []);

  return (
    <div >
      {confirmationStatus === 'pending' && (
        <div style={{width:'50%',margin:'auto',marginTop:'200px'}}>
          <img  style={{width:'50%',margin:'auto'}}src={greentick} alt="" />
          <div  style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',width:'100%',marginTop:'20px'}}>
            <div>Already have an account ? <Link style={{textDecoration:'none'}} to='/login'>Login</Link></div>
            <div>Don't have an account ? <Link style={{textDecoration:'none'}} to="/register" >Register</Link></div>
          </div>
        </div>
      )}
      {confirmationStatus === 'error' && (
        <p>There was an error confirming the email. Please try again.</p>
      )}
    </div>
  );
};

export default ConfirmEmail;
