import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  SnackbarContent,
  Snackbar
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

export default function ResetLinkPageForm() {
  const navigate = useNavigate();
  const { userId, token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const RegisterSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password should contain minimum 8 characters')
      .matches(/^(?=.*[A-Z]).*$/, 'Password should contain at least one capital letter')
      .matches(/^(?=.*[@$!%*#?&]).*$/, 'Password should contain at least one special character')
      .matches(/^(?=(?:\D*\d){2}).*$/, 'Password should contain at least two digits')
      .matches(/^(?!.* ).*$/, 'Password should not contain spaces')
      .required('Required'),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      userId,
      token
    },
    validationSchema: RegisterSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/auth/password-reset`,
        values
      );

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.data.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          navigate('/login', { replace: true });
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>
        <Stack spacing={3}>
          {/* <TextField
              fullWidth
              label="Old Password"
              {...getFieldProps('oldPassword')}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            /> */}

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
