import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  Box,
  Button,
  SnackbarContent,
  Snackbar
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';

const EditEntity = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };
  const { id } = useParams();
  const entityId = id;

  const [entityState, setEntity] = useState({});
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const entitySchema = Yup.object().shape({
    entityName: Yup.string().required("Name can't be empty"),
    entityDescription: Yup.string()
  });

  const onSubmitEntity = async (values) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/entities/${entityId}`,
        values
      );
      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setResponseState({ error: false, msg: res.data.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.response) {
          setResponseState({ error: true, msg: res.data.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    } catch (err) {
      setResponseState({ error: true, msg: err?.response?.data.msg });
      setState({ open: true, vertical: 'top', horizontal: 'right' });
    }
  };

  const formik = useFormik({
    initialValues: {
      entityName: entityState.name || '',
      entityDescription: entityState.description || ''
    },
    enableReinitialize: true,
    validationSchema: entitySchema,
    onSubmit: onSubmitEntity
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  useEffect(() => {
    const fetchEntity = async (entityId) => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/entities/${entityId}`
      );

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setEntity(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
        }
      }
    };
    fetchEntity(entityId);
  }, []);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/entities">
            Entities
          </Link>
          Entity
        </Breadcrumbs>

        <InputLabel htmlFor="entityName" style={marginStyles}>
          {' '}
          Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="entityName"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('entityName')}
          helperText={touched.entityName && errors.entityName}
          error={Boolean(touched.entityName && errors.entityName)}
        />

        <InputLabel htmlFor="entityDescription" style={marginStyles}>
          Description
        </InputLabel>
        <TextField
          name="entityDescription"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('entityDescription')}
        />

        <Box mt={3}>
          <Button type="reset" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Submit{' '}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default EditEntity;
