import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  Box,
  Button,
  MenuItem,
  SnackbarContent,
  Snackbar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';

import { fetchEntities } from '../../api/siteList/uploadSite/fetchEntities';

const SiteStudy = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const [entities, setEntities] = useState([]);
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const siteSchema = Yup.object().shape({
    siteName: Yup.string().required('Please enter Site Name'),
    siteShortName: Yup.string().required('Please enter Short Name'),
    siteAddress: Yup.string().required('Please enter Address'),
    institutionName: Yup.string().required('Please enter Institution Name'),
    siteEntityId: Yup.string().required('Please select entity')
  });

  const formik = useFormik({
    initialValues: {
      siteName: '',
      siteShortName: '',
      siteAddress: '',
      siteEntityId: '',
      institutionName: '',
      sitebleedSubscription:false
    },
    validationSchema: siteSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post(`${process.env.REACT_APP_API_SERVER_URL}/api/sites`, values)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 201) {
          setResponseState({ error: false, msg: res.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          resetForm(formik.initialValues);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchEntities();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setEntities(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
        }
      }
    };
    fetchData();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/sitelist">
            SiteList
          </Link>
          <Link color="textPrimary" to="/dashboard/sitelist/create-site" aria-current="page">
            Site Study
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="siteName" style={marginStyles}>
          Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteName"
          variant="outlined"
          label="Site Name"
          style={{ width: '70%' }}
          {...getFieldProps('siteName')}
          helperText={touched.siteName && errors.siteName}
          error={Boolean(touched.siteName && errors.siteName)}
        />

        <InputLabel htmlFor="siteShortName" style={marginStyles}>
          Short Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteShortName"
          variant="outlined"
          label="Site Short Name"
          style={{ width: '70%' }}
          {...getFieldProps('siteShortName')}
          helperText={touched.siteShortName && errors.siteShortName}
          error={Boolean(touched.siteShortName && errors.siteShortName)}
        />

        <InputLabel htmlFor="siteAddress" style={marginStyles}>
          Address <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteAddress"
          variant="outlined"
          label="Site Address"
          style={{ width: '70%' }}
          {...getFieldProps('siteAddress')}
          helperText={touched.siteAddress && errors.siteAddress}
          error={Boolean(touched.siteAddress && errors.siteAddress)}
        />

        <InputLabel htmlFor="siteEntityId" style={marginStyles}>
          Entity <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          select
          name="siteEntityId"
          variant="outlined"
          label="Site Entity"
          style={{ width: '70%' }}
          {...getFieldProps('siteEntityId')}
          helperText={touched.siteEntityId && errors.siteEntityId}
          error={Boolean(touched.siteEntityId && errors.siteEntityId)}
        >
          <MenuItem value="Select Entity Id" disabled>
            Select Entity
          </MenuItem>
          {/* <MenuItem value='Enity Id 2'>Entiy Id 2</MenuItem>
                <MenuItem value='Enity Id 3'>Entiy Id 3</MenuItem>
                <MenuItem value='Enity Id 4'>Entiy Id 4</MenuItem> */}
          {entities.map((entity, index) => (
            <MenuItem value={entity._id} key={index}>
              {entity.name}
            </MenuItem>
          ))}
        </TextField>

        <InputLabel htmlFor="institutionName" style={marginStyles}>
          Institution Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="institutionName"
          variant="outlined"
          label="Institution Name"
          style={{ width: '70%' }}
          {...getFieldProps('institutionName')}
          helperText={touched.institutionName && errors.institutionName}
          error={Boolean(touched.institutionName && errors.institutionName)}
        />
           <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps('sitebleedSubscription')}
                    // helperText={touched.sitebleedSubscription && errors.sitebleedSubscription}
                    // error={Boolean(touched.sitebleedSubscription && errors.sitebleedSubscription)}
                    // checked={!!rest.values.sitebleedSubscription}
                    // onChange={handleFormUpdate}
                    name="sitebleedSubscription"
                  />
                }
                label="Enable bleed subscription"
              />
            </Box>

        <Box mt={3}>
          <Button type="reset" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            {' '}
            Reset{' '}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {' '}
            Create{' '}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default SiteStudy;
