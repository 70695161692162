import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Button, SnackbarContent, Snackbar } from '@material-ui/core';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedInUser } from '../../../../redux/actions/authActions';
import logo from '../../../../assests/Images/logo.jpg';

const useStyles = makeStyles({
  form: {
    margin: '0 auto',
    width: '450px',
    padding: '0 0 20px 0'
  },
  mainHeading: {
    margin: '12% 20%'
  },
  formBody: {
    padding: '20px',
    backgroundColor: '#fff',
    border: '2px solid white',
    borderRadius: '10px',
    boxShadow: '0 0 2px 2px lightgrey',
    display: 'flex',
    flexDirection: 'column'
  },
  H2: {
    textAlign: 'center'
  },
  para: {
    margin: '10px',
    padding: '5px'
  },
  input: {
    margin: '10px 0'
  },
  btn: {
    margin: '4% 30%',
    padding: '10px 20px'
  },
  back: {
    marginLeft: '15px'
  }
});

const FirstLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { user } = useSelector((state) => state.loggedInUser);

  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const validationSchemas = Yup.object().shape({
    newPassword: Yup.string().required('This field is required'),
    confirmNewPassword: Yup.string()
      .required('This field is required')
      // eslint-disable-next-line consistent-return
      .when('newPassword', (password, field) => {
        if (password) {
          return field
            .required('The passwords do not match')
            .oneOf([Yup.ref('newPassword')], 'The passwords do not match');
        }
      })
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: validationSchemas,
    onSubmit: async (values) => {
      const payload = {
        password: values.newPassword,
        confirmPassword: values.confirmNewPassword
      };
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/users/reset-password/${user.id}`,
          payload
        );
        if (res) {
          if (res.message === 'Network Error') {
            setResponseState({ error: true, msg: res.data.message });
            setState({ open: true, vertical: 'top', horizontal: 'right' });
          } else if (res.status === 200) {
            setResponseState({ error: false, msg: res.data.msg });
            setState({ open: true, vertical: 'top', horizontal: 'right' });
            setTimeout(() => {
              const userData = JSON.parse(localStorage.loggedInUser);
              dispatch(setLoggedInUser({ ...userData, newLogin: false }));
              localStorage.setItem(
                'loggedInUser',
                JSON.stringify({ ...userData, data: { ...userData.data }, newLogin: false })
              );
            }, 1000);
          }
        }
      } catch (err) {
        setResponseState({ error: true, msg: err?.response.data.msg });
        setState({ open: true, vertical: 'top', horizontal: 'right' });
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  return (
    <>
      <Snackbar
        severity={responseState.error ? 'red' : 'green'}
        autoHideDuration={5000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent
          style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
          message={responseState.msg}
        />
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.mainHeading}>
            <img src={logo} alt="Neuro-Shield" width="300" />
          </div>
          <div className={classes.formBody}>
            <h2 className={classes.H2}>Update your password</h2>
            <p className={classes.para}>
              You need to update your password because this is the first time you are signing in.
            </p>

            <TextField
              type="password"
              className={classes.input}
              fullWidth
              placeholder="New Password"
              name="newPassword"
              {...getFieldProps('newPassword')}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              type="password"
              className={classes.input}
              fullWidth
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              {...getFieldProps('confirmNewPassword')}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />

            <br />
            <Button className={classes.btn} variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FirstLogin;
