import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Label from '../../components/Label';
import logo from '../../assests/Images/INMED AI STUDIO final-01 1.png';
import neuroBleed from '../../assests/Images/Neuroshield-Bleed.png';
import neuroVolumetry from '../../assests/Images/Neuroshiled-volumetry.png';
import './SiteAccess.css';

function SiteAccess() {
  const { passcode } = localStorage.loggedInUser
    ? JSON.parse(localStorage.loggedInUser)
    : { passcode: '' };
  return (
    <>
      <Box>
        <Box pt={4} width="14%" margin="auto">
          <img width="100%" alt="logo" src={logo} />
        </Box>
        <Box width="70%" alignContent="space-evenly" margin="auto" mt={4} display="flex" gap="25px">
          <a href={`${process.env.REACT_APP_NEUROFLO_WEB_URL}/login/${passcode}`}>
            <Box p={9} borderRadius="10px" boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
              <img width="90%" style={{ margin: 'auto' }} alt="neuroshiled" src={neuroBleed} />
            </Box>
          </a>
          <Link style={{ textDecoration: 'none' }} to="/dashboard/app">
            <Box
              pt={9}
              pl={9}
              pr={9}
              pb={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRadius="10px"
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
            >
              {' '}
              <img width="90%" style={{ margin: 'auto' }} alt="neuroshiled" src={neuroVolumetry} />
              <Box className="fda-approved-text">FDA Approved</Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
}
export default SiteAccess;
