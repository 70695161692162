import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/authentication/login/Login';
import Register from './pages/authentication/register/Register';
import DashboardApp from './pages/dashboard/DashboardApp';
import WorkList from './pages/workList/worklist/WorkList';
import WorklistReport from './pages/workList/worklist/WorklistReport';
import NotFound from './pages/error/Page404';
import UploadStudy from './pages/workList/uploadStudy/UploadStudy';
import SiteList from './pages/siteList/SiteList';
import SiteStudy from './pages/siteList/CreateSite';
import EditSite from './pages/siteList/EditSite';
import UserList from './pages/user/UserList';
import AddUser from './pages/user/AddUser';
import EditUser from './pages/user/EditUser';
import EntityList from './pages/entities/EntityList';
import CreateEntity from './pages/entities/CreateEntity';
import UserDetails from './pages/user/UserDetails';
import SiteDetails from './pages/siteList/SiteDetails';
import EditEntity from './pages/entities/EditEntity';
import ResetPassword from './pages/authentication/reset-password/ResetPassword';
import Profile from './pages/profile/Profile';
import UploadSegmentation from './pages/workList/uploadSegmentation/UploadSegmentation';
import SiteAccess from './pages/siteaccess/siteAccess';
import UpdateStudy from './pages/workList/worklist/update-worklist/UpdateStudy';
import ResetLinkPage from './pages/authentication/resetPasswordLinkPage/ResetLinkPage';
import ConfirmEmail from './pages/authentication/email-verification';
import ForgotPassword from './pages/authentication/forgot-password/ForgotPassword';
import RegionLogin from './pages/authentication/regionLogin';

// ----------------------------------------------------------------------

export default function Router() {
  const isAuthenticated = useSelector((state) => state.loggedInUser.isAuthenticated);
  const hasBleedSubscription = useSelector(
    (state) => state.loggedInUser.user.sitebleedSubscription
  );
  const { user } = useSelector((state) => state.loggedInUser);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isManager, setIsManager] = useState(true);
  const [isUser, setIsUser] = useState(true);

  useEffect(() => {
    if (user.role === 'Admin') {
      setIsAdmin(true);
      setIsUser(false);
    } else {
      setIsUser(true);
      setIsAdmin(false);
    }
  }, [user]);

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: !isAuthenticated ? (
            <Login />
          ) : (
            <Navigate to={hasBleedSubscription ? '/siteaccess' : '/dashboard/app'} />
          )
        },
        { path: 'confirmEmail', element: <ConfirmEmail /> },
        { path: 'register', element: <Register /> },
        { path: 'reset-password-link/:userId/:token', element: <ResetLinkPage /> },
        { path: '404', element: <NotFound /> },
        { path: 'upload', element: <UploadStudy /> },
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'siteaccess',
          element: hasBleedSubscription ? <SiteAccess /> : <Navigate to="/dashboard/app" />
        },
        { path: 'register', element: <Register /> }
      ]
    },
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <WorkList /> },
        { path: 'worklist', element: <WorkList /> },
        {
          path: 'sitelist',
          element: isAdmin || isUser ? <SiteList /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users',
          element: isAdmin || isUser ? <UserList /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entities',
          element: isAdmin || isUser ? <EntityList /> : <Navigate to="/dashboard/app" />
        },
        // { path: 'entities/edit-entity/:id' , element: isAdmin ? <EditEntity /> : <Navigate to="/dashboard/app" /> },
        {
          path: 'worklist/upload',
          element: isAuthenticated ? <UploadStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/upload-segmentation',
          element: isAdmin || isUser ? <UploadSegmentation /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/edit-study/:id',
          element: isAdmin || isUser ? <UpdateStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'worklist/report',
          element: isAdmin || isUser ? <WorklistReport /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/create-site',
          element: isAdmin || isUser ? <SiteStudy /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/site-details',
          element: isAdmin || isUser ? <SiteDetails /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'sitelist/edit-site/:id',
          element: isAdmin || isUser ? <EditSite /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/add-user',
          element: isAdmin || isUser ? <AddUser /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/edit-user',
          element: isAdmin || isUser ? <EditUser /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'users/user-details',
          element: isAdmin || isUser ? <UserDetails /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entities/create-entity',
          element: isAdmin || isUser ? <CreateEntity /> : <Navigate to="/dashboard/app" />
        },
        {
          path: 'entities/edit-entity/:id',
          element: isAdmin || isUser ? <EditEntity /> : <Navigate to="/dashboard/app" />
        },
        { path: '/profile', element: <Profile /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" /> }
  ]);
}
