import axios from 'axios';


export const fetchEntities = () =>(

    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/entities`)
    .catch((error)=>(error))

)

