import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Toolbar,
  Tooltip,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 120,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '20%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '22%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterUserName: PropTypes.string,
  onFilterUserName: PropTypes.func,
  sitesOfLoggedUser: PropTypes.array,
  onSiteId: PropTypes.func,
  filteredSiteId: PropTypes.string,
  sites: PropTypes.array
};

export default function UserListToolbar({
  numSelected,
  filterUserName,
  onFilterUserName,
  onSiteId,
  filteredSiteId,
  sites
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container spacing={2} mt={1}>
          <SearchStyle
            value={filterUserName}
            onChange={onFilterUserName}
            placeholder="Username"
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            style={{ margin: '2px 5px' }}
          />
          {/* <SearchStyle placeholder="NSID" style={{margin:'2px 5px'}} value={} onChange={onFilterPatientNSID}/> */}
        </Grid>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
          style={{ width: '15%' }}
          select
          label="Filter Sites"
          value={filteredSiteId}
          onChange={onSiteId}
        >
          <MenuItem value=" ">All Sites</MenuItem>
          {sites.map((site, index) => (
            <MenuItem key={index} value={site._id}>
              {site.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </RootStyle>
  );
}
