import axios from 'axios';



export const fetchUser = (edituserId) =>(

    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${edituserId}`)
    .catch((error)=>(error))
    )


