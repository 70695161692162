import React, { useState, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  TextField,
  InputLabel,
  Box,
  Button,
  MenuItem,
  SnackbarContent,
  Snackbar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';

import { fetchEntities } from '../../api/siteList/uploadSite/fetchEntities';
import { fetchSite } from '../../api/siteList/fetchSite';

const EditSite = () => {
  const marginStyles = {
    margin: '15px 0 10px 5px'
  };

  const [entities, setEntities] = useState([]);
  const [siteState, setSiteState] = useState({});
  const { id } = useParams();
  const siteId = id;
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const siteSchema = Yup.object().shape({
    siteName: Yup.string().required('Required'),
    siteShortName: Yup.string().required('Required'),
    siteAddress: Yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {
      siteName: siteState.name || '',
      siteAddress: siteState.address || '',
      siteShortName: siteState.shortName || '',
      siteInstitutionName: siteState.institutionName || 'NA',
      siteEntityId: siteState.entityId || '',
      siteEntityName: siteState.entityName || 'NA',
      sitebleedSubscription: siteState.sitebleedSubscription || false
    },
    enableReinitialize: true,
    validationSchema: siteSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .patch(`${process.env.REACT_APP_API_SERVER_URL}/api/sites/${id}`, values)
        .catch((error) => error);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSiteState(res.data.data);
          setResponseState({ error: false, msg: 'Site Updated Successfully' });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
          resetForm({ values: res.data.data });
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchEntities();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setEntities(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
        }
      }
    };

    const fetchSiteData = async (siteId) => {
      const res = await fetchSite(siteId);
      const { entityId } = res?.data.data;
      try {
        const entityResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER_URL}/api/entities/${entityId}`
        );
        setSiteState({
          name: res.data.data.name,
          shortName: res.data.data.shortName,
          address: res.data.data.address,
          entityName: entityResponse.data.data.name,
          entityId: res.data.data.entityId,
          institutionName: res.data.data.institutionName ?? 'NA',
          sitebleedSubscription: res?.data?.data?.sitebleedSubscription
        });
      } catch (error) {
        console.error(error);
        setSiteState({
          name: res.data.data.name,
          shortName: res.data.data.shortName,
          address: res.data.data.address,
          institutionName: res.data.data.institutionName ?? 'NA',
          sitebleedSubscription: res?.data?.data?.sitebleedSubscription
        });
      }
    };
    fetchData();
    fetchSiteData(siteId);
  }, [siteId]);

  useEffect(() => {
    if (siteState) {
      setFieldValue('siteName', siteState.name || '');
      setFieldValue('siteAddress', siteState.address || '');
      setFieldValue('siteShortName', siteState.shortName || '');
      setFieldValue('siteInstitutionName', siteState.institutionName || '');
      setFieldValue('siteEntityId', siteState.entityId || '');
      setFieldValue('siteEntityName', siteState.entityName || '');
      setFieldValue('sitebleedSubscription', siteState.sitebleedSubscription || false);
    }
  }, [siteState, setFieldValue]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={5000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Breadcrumbs aria-label="breadcrumb" my={3}>
          <Link color="inherit" to="/dashboard/sitelist">
            SiteList
          </Link>
          <Link color="textPrimary" to="/dashboard/sitelist/create-site" aria-current="page">
            Site Study
          </Link>
        </Breadcrumbs>

        <InputLabel htmlFor="siteName" style={marginStyles}>
          Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteName"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('siteName')}
          helperText={touched.siteName && errors.siteName}
          error={Boolean(touched.siteName && errors.siteName)}
        />

        <InputLabel htmlFor="siteShortName" style={marginStyles}>
          Short Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteShortName"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('siteShortName')}
          helperText={touched.siteShortName && errors.siteShortName}
          error={Boolean(touched.siteShortName && errors.siteShortName)}
        />

        <InputLabel htmlFor="siteAddress" style={marginStyles}>
          Address <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteAddress"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('siteAddress')}
          helperText={touched.siteAddress && errors.siteAddress}
          error={Boolean(touched.siteAddress && errors.siteAddress)}
        />

        <InputLabel htmlFor="siteEntityId" style={marginStyles}>
          Entity
        </InputLabel>
        <TextField
          select
          value={getFieldProps('siteEntityId').value}
          name="siteEntityId"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('siteEntityId')}
        >
          <MenuItem value="" disabled>
            Select Entity
          </MenuItem>
          {entities.map((entity, index) => (
            <MenuItem value={entity._id} key={index}>
              {entity.name}
            </MenuItem>
          ))}
        </TextField>

        <InputLabel htmlFor="siteInstitutionName" style={marginStyles}>
          Institution Name <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          name="siteInstitutionName"
          variant="outlined"
          style={{ width: '70%' }}
          {...getFieldProps('siteInstitutionName')}
          helperText={touched.siteInstitutionName && errors.siteInstitutionName}
          error={Boolean(touched.siteInstitutionName && errors.siteInstitutionName)}
        />

        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('sitebleedSubscription')}
                name="sitebleedSubscription"
                checked={formik.values.sitebleedSubscription}
              />
            }
            label="Enable bleed subscription"
          />
        </Box>

        <Box mt={3}>
          <Button type="reset" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            Reset
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default EditSite;
