import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
import pinFill from '@iconify/icons-eva/pin-fill';
import listOutline from '@iconify/icons-eva/list-outline';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const useSidebarConfig = () => {
  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

  const { user } = useSelector((state) => state.loggedInUser);
  let SidebarConfig;

  if (user.role === 'User') {
    const sidebarConfigManager = [
      // {
      //   title: 'dashboard',
      //   path: '/dashboard/app',
      //   icon: getIcon(pieChart2Fill)
      // },
      {
        title: 'WorkList',
        path: '/dashboard/worklist',
        icon: getIcon(listOutline)
      }
    ];

    SidebarConfig = sidebarConfigManager;
    return SidebarConfig;
  }

  const sidebarConfigAdmin = [
    // {
    //   title: 'dashboard',
    //   path: '/dashboard/app',
    //   icon: getIcon(pieChart2Fill)
    // },
    {
      title: 'Worklist',
      path: '/dashboard/worklist',
      icon: getIcon(listOutline)
    },
    {
      title: 'Entities',
      path: '/dashboard/entities',
      icon: <ApartmentIcon />
    },
    {
      title: 'Sites',
      path: '/dashboard/sitelist',
      icon: getIcon(pinFill)
    },
    {
      title: 'Users',
      path: '/dashboard/users',
      icon: getIcon(peopleFill)
    }
  ];

  SidebarConfig = sidebarConfigAdmin;

  return SidebarConfig;
};

export default useSidebarConfig;
