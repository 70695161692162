import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import logo from "../assests/Images/Neuroshiled-volumetry.png";
import logo1 from "../assests/Images/logo.png"
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src={logo} sx={{ width: 195, margin:'auto', height: 110, ...sx }} />;
}
