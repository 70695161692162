import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Tooltip,
  Stack,
  Button,
  Snackbar,
  SnackbarContent,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import searchFill from '@iconify/icons-eva/search-fill';
import { Edit, Assignment } from '@material-ui/icons';

// components
import Page from '../../components/Page';
// import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { WorkListHead } from '../workList/worklist/index';
import { fetchUsers } from '../../api/userList/fetchUsers';
import { fetchSites } from '../../api/uploadStudy/fetchSites';
import { setUserId } from '../../redux/actions/authActions';
import UserListToolbar from './UserListToolbar';

//
// import userLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'sites', label: 'Sites', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.username?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('patientId');
  const [filterUserName, setFilterUserName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [USERLIST,setUSERLIST] = useState([]);
  const [searchedSite] = useState('');
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  // const [deleteSiteId,setDeleteSiteId] = useState('');
  const [filteredSiteId, setFilteredSiteId] = useState('');

  const dispatch = useDispatch();

  const [responseState, setResponseState] = useState({ error: false, msg: '' });

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const iconStyle = {
    backgroundColor: '#dcf2d8',
    color: '#4fa52e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'pointer'
  };
  const { open, vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    async function fetchData(filteredSiteId) {
      const res = await fetchUsers(filteredSiteId);

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setUsers(res.data.data);
        } else if (res.response) {
          setResponseState({ error: false, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    }
    fetchData(filteredSiteId);
  }, [filteredSiteId]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.message === 'Network Error') {
          setResponseState({ error: true, msg: res.message });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        } else if (res.status === 200) {
          setSites(res.data.data);
        } else if (res.response) {
          setResponseState({ error: true, msg: res.response.data.msg });
          setState({ open: true, vertical: 'top', horizontal: 'right' });
        }
      }
    };
    fetchData();
  }, []);

  function handleSiteName(fetchedSites) {
    const siteNames = [];

    for (let i = 0; i < fetchedSites.length; i += 1) {
      for (let j = 0; j < sites.length; j += 1) {
        if (fetchedSites[i] === sites[j]._id) {
          siteNames.push(`${sites[j].name}, `);
        }
      }
    }
    return siteNames;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = ( patientId) => {
  //   const selectedIndex = selected.indexOf(patientId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, patientId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditUser = (id) => {
    dispatch(setUserId(id));
  };

  const handleFilterByUserName = (e) => {
    setFilterUserName(e.target.value);
  };

  const handleSiteId = (e) => {
    setFilteredSiteId(e.target.value);
  };

  const { user } = useSelector((state) => state.loggedInUser);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredusers = applySortFilter(users, getComparator(order, orderBy), filterUserName);

  const isUserNotFound = filteredusers.length === 0;

  return (
    <Page title="Users | Neuro-Shield">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {user.role !== 'User' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/users/add-user"
              startIcon={<Icon icon={plusFill} />}
            >
              Add User
            </Button>
          )}
        </Stack>

        <Snackbar
          severity="red"
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent style={{ backgroundColor: 'red' }} message={responseState.msg} />
        </Snackbar>

        <Card>
          {user.role !== 'User' && (
            <UserListToolbar
              numSelected={selected.length}
              filterUserName={filterUserName}
              onFilterUserName={handleFilterByUserName}
              filteredSiteId={filteredSiteId}
              sitesOfLoggedUser={user.sites}
              onSiteId={handleSiteId}
              sites={sites}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredusers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { username, phone, email, _id: patientId, sites } = row;
                      const isItemSelected = selected.indexOf(patientId) !== -1;
                      return (
                        <TableRow
                          hover
                          key={patientId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => handleClick(patientId)}
                            />
                          </TableCell> */}
                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={patientId} >{capitalize(firstname[0]) || "NA"}</Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {firstname || "NA"}
                              </Typography>
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left">{username || 'NA'}</TableCell>
                          <TableCell align="left">{handleSiteName(sites) || 'NA'}</TableCell>
                          <TableCell align="left">{email || 'NA'}</TableCell>
                          <TableCell align="left">{phone || 'NA'}</TableCell>
                          {user.role !== 'User' && (
                            <TableCell align="left">
                              <RouterLink to="/dashboard/users/user-details">
                                <Tooltip title="Details" arrow>
                                  <Assignment
                                    style={iconStyle}
                                    onClick={() => {
                                      handleEditUser(patientId);
                                    }}
                                  />
                                </Tooltip>
                              </RouterLink>
                              <RouterLink to="/dashboard/users/edit-user">
                                <Tooltip title="Edit" arrow>
                                  <Edit
                                    style={iconStyle}
                                    onClick={() => {
                                      handleEditUser(patientId);
                                    }}
                                  />
                                </Tooltip>
                              </RouterLink>
                              {/* <DeleteUser patientId={patientId}/> */}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchedSite} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredusers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
