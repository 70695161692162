import React from 'react';
import moment from 'moment'
import {useSelector} from 'react-redux';
import { Grid, InputLabel, InputBase } from '@material-ui/core';

const WorklistRepNVA = ({getFieldProps, edit}) => {
  const marginStyles = {
    // fontWeight: 'bold',
    marginTop: '15px',
    marginBottom: '10px'
  };

  const hrStyle = {
    marginTop: '2rem',
    // marginBottom: '1rem',
    border: 0,
    borderTop: '1px solid rgba(0,0,0,0.1)'
  };
  const inputStyle = {
    border: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    borderRadius: '8px',
    '& input':{
      marginLeft: '5px',
    }
  }

  const {user} = useSelector((state) => state.loggedInUser);
  const NVA_DATA = [
    [
      {
        id: 1,
        name: 'PatientID',
        label: 'Patient ID',
        canEdit: false
      },
      // { id: 2, name: 'PatientCondition', label: 'Patient Condition', canEdit: false },
      { id: 3, name: 'SiteName', label: 'Site Name', canEdit: false }
    ],
    [
      // {
      //   id: 1,
      //   name: user.role !== 'Admin' && 'PatientName',
      //   label: 'Patient name',
      //   canEdit: true
      // },
      { id: 2, name: 'PatientAge', label: 'Patient age', canEdit: true, val: 87 },
      { id: 3, name: 'PatientGender', label: 'Patient gender', canEdit: true}
    ],
    [
      // { id: 1, name: 'PatientContact', label: 'Patient contact', canEdit: true },
      { id: 2, name: 'RefPhysician', label: 'Ref physician', canEdit: true },
      { id: 3, name: 'ExamDate', label: 'Exam date', canEdit: true }
    ]
  ];

  return (
    <div>
      <hr style={{ ...hrStyle, marginTop: '1rem' }} />
      <br />
      <h3>NeuroShield Volumetric Analysis (NVA)</h3>
      <br />

      {NVA_DATA.map((row, indx) => (
        <Grid container spacing={2} sx={{ width: '100%'}} key={indx}>
          {row.map((col) => {
            const { id, name, label, canEdit } = col;
            return (
              <Grid
                item
                container
                wrap="nowrap"
                alignItems="center"
                spacing={4}
                sm={4}
                xs={12}
                key={id}
              >
                <Grid item>
                  <InputLabel htmlFor={name} sx={marginStyles}>
                    {label}
                  </InputLabel>
                </Grid>
                <Grid item>
                    <InputBase name={name} readOnly {...getFieldProps(name)} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </div>
  );
};

export default WorklistRepNVA;
