import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  closeBtn: {
    position: 'relative',
    top: '0',
    left: '100%',
    '&:hover': {
      backgroundColor: 'lightgrey',
      border: '1px solid lightgrey',
      borderRadius: '50%',
      padding: '2px'
    }
  }
}));

export const modalStyle = {
  top: `${20}%`,
  left: `${26}%`,
  transform: `translate(${26}%, ${20}%)`,
  border:'none'
};

export const textStyle = {
  margin: '5px 0 5px 0'
};
