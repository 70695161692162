import { useState, useEffect } from 'react';
import { Table, TableCell, TableRow, Avatar, Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import unknown from '../../assests/Images/unknown.png';
import { fetchSites } from '../../api/uploadStudy/fetchSites';

const useStyles = makeStyles((theme) => ({
  profile: {
    margin: '5px',
    display: 'flex'
  },
  userInfo: {
    marginLeft: '10%',
    marginTop: '10%'
  },
  row: {
    margin: '15% 0'
  },
  tCell: {
    fontSize: '1.2rem',
    width: '250px',
    borderBottom: 'none'
  },
  img: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    marginTop: '35%'
  }
}));

function Profile() {
  const { user } = useSelector((state) => state.loggedInUser);
  const classes = useStyles();
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const fetchsites = async () => {
      const res = await fetchSites();

      if (res) {
        if (res.status === 200) {
          setSites(res.data.data);
        }
      }
    };
    fetchsites();
  }, []);

  const siteNameArray = [];

  for (let i = 0; i < sites.length; i += 1) {
    if (user.sites === undefined) {
      siteNameArray.push(' ');
    } else if (user.sites.includes(sites[i]._id)) {
      siteNameArray.push(sites[i].name);
    }
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" my={3}>
        <Link color="inherit" to="/dashboard/app">
          Dashboard
        </Link>
        <Link color="textPrimary" to="/dashboard/profile" aria-current="page">
          Profile
        </Link>
      </Breadcrumbs>
      <div className={classes.profile}>
        <div>
          <h2 style={{ margin: '10% 0 5% 0' }}>Profile</h2>
          <Avatar src={unknown} alt="pic" width="200px" className={classes.img} />
        </div>
        <div className={classes.userInfo}>
          <Table>
            <TableRow>
              <TableCell className={classes.tCell}>Name</TableCell>
              <TableCell className={classes.tCell}>{user.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tCell}>Username</TableCell>
              <TableCell className={classes.tCell}>{user.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tCell}>Email</TableCell>
              <TableCell className={classes.tCell}>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tCell}>Role</TableCell>
              <TableCell className={classes.tCell}>{user.role}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tCell}>Sites</TableCell>
              <TableCell className={classes.tCell}>
                <ul>
                  {user.sites.map((site) => (
                    <li style={{ margin: '5px' }} key={site}>
                      {site.name}
                    </li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Profile;
