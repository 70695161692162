import * as Yup from 'yup';
import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';
import { setLoggedInUser } from '../../../redux/actions/authActions';
import { useChangePassword } from './change-password/hooks';

export const useLogin= () => {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const usernameParam = queryParams.get('username');
    const [counter, setCounter] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [responseState, setResponseState] = useState({ error: false, msg: '' });
    const [savedLoginData, setSavedLoginData] = useState({
      username: usernameParam || '',
      password: '',
      remember: true
    });
    const [savedData, setSavedData] = useState(false);
    const dispatch = useDispatch();
    const [changePassword,setChangePassword]=useState(false)
    const [state, setState] = React.useState({
      open: false,
      vertical: 'top',
      horizontal: 'center'
    });
    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
      };
  
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
      });
    

    const formik = useFormik({
        initialValues: {
          username: usernameParam || savedData ? savedLoginData.username : '',
          password: savedData && !usernameParam ? savedLoginData.password : '',
          remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: async (value) => {
          try {
            const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/auth/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                username: value.username,
                password: value.password
              })
            });
            const resJson = await res.json();
            const firstLogin=resJson.data?.isFirstLogin
    
            if (res.status === 200) {
              setCounter(0);
            
                const loggedInUser = resJson;
                const { data: { sites = [] } = {} } = loggedInUser;
                const hasEnabledSitebleedSubscription = sites.find((site) => !!site.sitebleedSubscription);
    
                setAuthorizationToken(resJson.token);
                localStorage.setItem('passwordCheck',firstLogin)
    
                localStorage.setItem(
                  'loggedInUser',
                  JSON.stringify({
                    ...loggedInUser,
                    data: {
                      ...loggedInUser.data,
                      hasBleedSubscription: !!hasEnabledSitebleedSubscription
                    }
                  })
                );
    
                setResponseState({ success: true, msg: resJson.data.msg });
    
                dispatch(
                  setLoggedInUser({
                    ...loggedInUser,
                    data: { ...loggedInUser.data, hasBleedSubscription: !!hasEnabledSitebleedSubscription }
                  })
                ); 
               
    
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!hasEnabledSitebleedSubscription) {
                  navigate('/siteaccess', { replace: true });
                } else {
                  navigate('/dashboard/app', { replace: true });
                }
              }
    
            if (res.status === 401) {
              setResponseState({ error: true, msg: 'Invalid Credentials' });
              setState({ open: true, vertical: 'top', horizontal: 'right' });
              setCounter(resJson.data.failedLoginAttempts);
              setTimeout(() => {
                setResponseState({ error: false, msg: '' });
              }, 3000);
            }
            if(res.status===403){
              setResponseState({ error: true, msg: 'Invalid Credentials' });
              setState({ open: true, vertical: 'top', horizontal: 'right' });
    
            }
            if(res.status===404){
              setResponseState({ error: true, msg:resJson.msg });
              setState({ open: true, vertical: 'top', horizontal: 'right' });
    
            }

          } catch (err) {
            setResponseState({ error: true, msg: err.data.msg });
    
            setState({ open: true, vertical: 'top', horizontal: 'right' });
    
            setTimeout(() => {
              setResponseState({ error: false, msg: '' });
            }, 3000);
          }
        }
      });

      return {changePassword,navigate,location,queryParams,usernameParam,counter,showPassword,responseState,savedLoginData,savedData,setSavedData,setSavedLoginData,setShowPassword,formik,open,horizontal,vertical,handleClose}
    
    
}